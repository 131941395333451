import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue';

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'

const dropdownReferralTypeOptions = [
    { key: 'Cliente', value: 'Cliente' },
    { key: 'Ex-Cliente', value: 'Ex-Cliente' },
    { key: 'Codeudor', value: 'Codeudor' }
  ]

const dropdownDocumentTypeOptions = [
    { key: 'Cédula', value: 'Cédula' },
    { key: 'Pasaporte', value: 'Pasaporte' }
  ]

const dropdownRelationshipOptions = [
    { key: 'Amigo', value: 'Amigo'},
    { key: 'Cliente', value: 'Cliente'},
    { key: 'Conocido', value: 'Conocido'},
    { key: 'Familiar', value: 'Familiar'},
    { key: 'Proveedor', value: 'Proveedor'},
    { key: 'Socio de negocio', value: 'Socio de negocio'},
    { key: 'Vecino', value: 'Vecino'}
]

const dropdownPromotersOptions = [
    { key: 'Promotor', value: 'Promotor'}
  ]


const ReferralForm = props => {
            
    const [options, setOptions] = useState([]);

    const selectOption = (index) => {
        if (props.values.referral.referral_type == "Codeudor") {
            props.setFieldValue('referral.resource_id', options[index].AvalID)
            if (options[index].Cedula) {
                props.setFieldValue('referral.ident_clie', options[index].Cedula)
                props.setFieldValue('referral.ident_clie_type', 'Cédula')
            } else {
                props.setFieldValue('referral.ident_clie', options[index].Pasaporte)
                props.setFieldValue('referral.ident_clie_type', 'Pasaporte')
            }
            props.setFieldValue('referral.mobile_phone', options[index].TelefonoCel)
            props.setFieldValue('referral.phone', options[index].Telefono)
        } else {
            if (options[index].TipoIdentiID == 1) {
                props.setFieldValue('referral.ident_clie_type', 'Cédula')
            } else {
                props.setFieldValue('referral.ident_clie_type', 'Pasaporte')
            }
            props.setFieldValue('referral.ident_clie', options[index].NumIdentific)
            props.setFieldValue('referral.resource_id', options[index].ClienteID)
            props.setFieldValue('referral.mobile_phone', options[index].TelefonoCelular)
            props.setFieldValue('referral.phone', options[index].Telefono)              
        }
    }
 
    const [loading, setLoading] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [refresh, setRefresh] = useState(0);
    const [promoters, setPromoters] = useState([]);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(
        () => {
            if (debouncedSearchTerm) 
                    searchNames(debouncedSearchTerm);
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
    );

    useEffect(() => {
        setLoading(true);
        axios.get('/api/ext/promoters')
        .then( resp => {
            console.log(resp);
            setPromoters(resp.data.items);
            setLoading(false);
    
        })
        .catch( data => console.log('error', data));


    }, [refresh]);


    const optionNames = options.map(json => {
            return json.NombreCompleto
    })                

    const searchNames = (name) => {
        console.log(props.values.referral.referral_type)
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/clientes/search_names`, {
            params: {
                name: name,
                type: props.values.referral.referral_type
            }
        })
        .then( resp => {
            setOptions(resp.data);
        })
        .catch( error => {return error});

    }

    return (
        <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">            
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                Referido por
                </h3>
            </div>
            <div className="px-4 py-5 sm:px-6">
                {
                    props.contract.any_accessory_13 &&
                    <dl className="mb-10 grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4"> 
                        <FormikControl
                            control='select'
                            label='Tipo de Referencia'
                            name={`referral.referral_type`}
                            options={dropdownReferralTypeOptions}
                        />
                        <FormikControl
                            control='autocomplete'
                            label='Nombre y Apellido'
                            name={`referral.name`}
                            // resourceID={`referral.resource_id`}
                            options={optionNames}
                            // onChange={debouncedSearchNames}
                            // onChange={(val) => setSearchTerm(val)}
                            onChange={setSearchTerm}
                            onSelect={selectOption}
                            />
                        <FormikControl
                            control='input'
                            label='Documento de Identidad'
                            name={`referral.ident_clie`}
                            />
                        <FormikControl
                            control='select'
                            label='Tipo de Documento'
                            name={`referral.ident_clie_type`}
                            options={dropdownDocumentTypeOptions}      
                        />
                        <FormikControl
                            control='input'
                            label='Teléfono Celular'
                            name={`referral.mobile_phone`}

                        />
                        <FormikControl
                            control='input'
                            label='Otro Teléfono'
                            name={`referral.phone`}

                        />
                        <FormikControl
                            control='select'
                            label='Relación/Parentesco'
                            name={`referral.relationship`}
                            options={dropdownRelationshipOptions}
                        />
                    </dl>
                }
                {
                    props.contract.any_accessory_24 &&
                    <dl className="mb-10 grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4"> 
                        <FormikControl
                            control='select'
                            label='Promotor'
                            name={`referral.name`}
                            options={dropdownPromotersOptions}
                        />
                        <FormikControl
                            control='input'
                            label='Teléfono Celular'
                            name={`referral.mobile_phone`}

                        />
                        <FormikControl
                            control='input'
                            label='Otro Teléfono'
                            name={`referral.phone`}

                        />
                    </dl>
                }
            </div>
        </div>    
    )
}

function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }


export default ReferralForm;
