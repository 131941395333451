import React, {useState, useCallback, useRef, useEffect} from "react";
import { Formik, Form } from 'formik';
import axios from 'axios'
import FormikControl from '../forms/FormikControl';
import { dropdownClienteType, dropDownProductOptions, dropdownGarantias, dropdownFrecuenciaOptions, dropDownComCierre, dropdownCheque, dropdownCod, dropdownJub, dropdownDiaMesPagoOptions, dropdownGastosLegales, dropdownVehiculos, dropdownHipotecas } from './Options';
import { calculateAgeDetails } from "./AgeDetails";
import { calculateFactors } from "./Factors";
import { calculateTimbresFiscales } from "./Timbres";
import { calculateExequialExpenses } from "./Exequial";
import { calculateSeguroDesgravamen } from "./Desgravamen";
import { getPlazos } from "./Plazos";
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/solid';
import { getAccesorios } from "./Accesorios";
import { ClienteData } from "./ClientData";
import { getDestinos } from "./Destinos";
import { ButtonsSimulacion } from "./ButtonsSimulacion";
// import { ButtonsShare } from "./ButtonsShare";
import { TablaSimulacion } from "./TablaSimulacion"

const ButtonsShare = ({ simulacionID }) => {

    const handleClickCotizacion = (simulacionID) => {
        window.open('https://www.sumafinanciera.com/landing/print.php?_id='+simulacionID);
       //window.open('http://localhost:8888/print_oferta.php?_id='+simulacionID);
      };
      
      const handleClickMinuta = (simulacionID) => {
          window.open('https://www.sumafinanciera.com/landing/print_minuta.php?_id='+simulacionID);
         //window.open('http://localhost:8888/print_oferta.php?_id='+simulacionID);
        };
      
      const updateCotizacion = (cotizaID) => {
          const token = document.querySelector('[name=csrf-token]').content
          axios.defaults.headers.common['X-CSRF-TOKEN'] = token
          //alert(simulacionID.$oid)compartida
          axios.post(`/api/v1/safi/update_simulation`, {...{cotizaID}, ...{Estatus: 'Cotizacion Compartida'}} )
            .then( resp => {
              //console.log('response',resp);
              navigator.clipboard.writeText('https://www.sumafinanciera.com/landing/index.php?_id='+cotizaID)
      
            })
            .catch( data => console.log('error', data))
      }
      
      const crearCotizacion = (cotizaID) => {
          //alert(simulacionID.$oid)
          axios.post(`/api/v1/safi/update_simulation`, {...{cotizaID}, ...{Estatus: 'Cotizacion Creada'}, ...{EsCotizacion: 'Si'}} )
            .then( resp => {
              //console.log('response',resp);
              //setSimulacionID(resp.data.result._id.$oid)
      
            })
            .catch( data => console.log('error', data))
        }


    let buttonsShare;
    const simId = simulacionID && simulacionID.length > 0 ? simulacionID[0] : null;



    buttonsShare = (
        <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">

        
       

          <div className="ml-2 mt-2 inline-flex">
            <button type="button"
              id="sharethree"
              className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              onClick={() => handleClickCotizacion(simulacionID)}
              hidden
            >
              Copiar Enlace para Cliente
            </button>
          </div>

          <div className="ml-2 mt-2 inline-flex">
            <button type="button"
              id="sharefourth"
              className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              onClick={() => handleClickCotizacion(simulacionID)}
              hidden
            >
              Ver Cotizacion
            </button>
          </div>

          <div className="ml-2 mt-2 inline-flex">
            <button type="button"
              id="sharefourth"
              className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              onClick={() => handleClickMinuta(simulacionID)}
              hidden
            >
              Ver Minuta
            </button>
          </div>

          
          <div className="ml-2 mt-2 inline-flex">
            <button type="button"
              id="shareone"
              className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              //onClick={crearCotizacion}
              onClick={() => crearCotizacion(simId)}
              hidden
            >
              Enviar Cotización a Atención al Cliente
            </button>
          </div>
          </div>
      )
    
    return(
      <div>
        {buttonsShare}
      </div>

    )

}



const DatosSimulacion = (props) => {
    
    const [nombreCompletoCliente, setNombreCompletoCliente] = useState('');
    const [fechaNacimientoCliente, setFechaNacimientoCliente] = useState('');
    const [cedulaIdent, setCedulaIdent] = useState('');
    const [tipoPersonaCliente, setTipoPersonaCliente] = useState(''); // State to store the customer name
    const [isLocked, setIsLocked] = useState(false);
    const [destinos, setDestinos] = useState([]);
    const [plazos, setPlazos] = useState([]);
    const [frecuencias, setFrecuencias] = useState([]);




    let gastoLegalMonto = Number(0.00);
    let gastoLegalMontoImpuesto = Number(0.00);
    let gastoLegalConImpuesto = Number(0.00);
    let gastoTotalAccesorios = Number(0.00);
    let comisionPorCierre = Number(0.00);
    let comisionPorCierreImpuesto = Number(0.00);
    let comisionPorCierreConImpuesto = Number(0.00);

    let gastoTotalAccesoriosSinCierre = Number(0.00);
    let timbresFiscales = Number(0.00);
    let termInMonths = Number(0.00);

    let seguroDesgravamenMonto = Number(0.00);
    let seguroDesgravamenMontoImpuesto = Number(0.00);
    let seguroDesgravamenConImpuesto = Number(0.00);

    let factorSeguroDesgravamen = Number(0.00);
    let factorComisionSeguroDesgravamen = Number(0.00);


    let comisionDesgravamenMonto = Number(0.00);
    let comisionDesgravamenMontoImpuesto = Number(0.00);
    let comisionDesgravamenConImpuesto = Number(0.00);

    let impuestoDesgravamenMonto = Number(0.00);
    let impuestoDesgravamenMontoImpuesto = Number(0.00);
    let impuestoDesgravamenConImpuesto = Number(0.00);

    let chequeCertificadoMonto = Number(0.00);
    let chequeCertificadoMontoImpuesto = Number(0.00);
    let chequeCertificadoConImpuesto = Number(0.00);

    let pagoReferidoMonto = Number(0.00);
    let pagoReferidoMontoImpuesto = Number(0.00);
    let pagoReferidoConImpuesto = Number(0.00);

    let totalExequiasMonto = Number(0.00);
    let totalExequiasMontoImpuesto = Number(0.00);
    let totalExequiasConImpuesto = Number(0.00);

    let memorialExequialMonto = Number(0.00);
    let memorialExequialMontoImpuesto = Number(0.00);
    let memorialExequialConImpuesto = Number(0.00);

    let comisionMemorialMonto = Number(0.00);
    let comisionMemorialMontoImpuesto = Number(0.00);
    let comisionMemorialConImpuesto = Number(0.00);

    let accesorio1 = Number(0.00);
    let accesorio6 = Number(0.00);
    let accesorio11 = Number(0.00);

    let totalMontoFinanciadoMonto = Number(0.00);
    let gastoTotalAccesoriosSinDesgravamen = Number(0.00);

    let btnRef = useRef();
    let btnShareRef = useRef();

    const [simulation, setSimulation] = useState([]);

    const [simulacionID, setSimulacionID] = useState([]);
  
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [accesorios, setAccesorios] = useState([]);
  
    const [totalCredito, setTotalCredito] = useState(0);
  
    const [totalAccesoriosState, setTotalAccesorios] = useState(0);
    const [gastoLegal, setGastoLegal] = useState([]);
    //const [gastoLegalMonto, setGastoLegalMonto] = useState([]);
  
    const [firmaRuego, setFirmaRuego] = useState(0);
    const [tipoPersona, setTipoPersona] = useState(0);
    const [contratoPrendario, setContratoPrendario] = useState(0);
  
    const [edadCliente, setEdadCliente] = useState([]);
  
  
    const [loading, setLoading] = useState([]);
    const [refresh, setRefresh] = useState(0);
  
    const [calculated, setCalculated] = useState(0);
  
  
    const [seguroDesgravamen, setSeguroDesgravamen] = useState(0);
  
    const [nombreCompletoUsuario, setNombreCompletoUsuario] = useState([]);
  
    const [accesoriosCotizacion, setAccesoriosCotizacion] = useState([]);

    

    const computeTotalTax = (accesorios) => {
        let totalTax = 0;
        for (const key in accesorios) {
            if (accesorios.hasOwnProperty(key) && key.endsWith('_tax')) {
                totalTax += accesorios[key] ? Number(accesorios[key]) : 0;
            }
        }
        return totalTax;
    };

    const computeTotalCharges = (accesorios) => {
        let totalCharges = 0;
        for (const key in accesorios) {
            if (accesorios.hasOwnProperty(key) && key.endsWith('_total')) {
                totalCharges += accesorios[key] ? Number(accesorios[key]) : 0;
            }
        }
        return totalCharges;
    };

    const onSubmit = (values) => {

        var compendioAccesorios = []
        let compendioObject = {}

        console.log('accesoriosORIOS', values.accesoriosList)

        const totalITBMS = computeTotalTax(values.accesorios);
        const totalCharges = computeTotalCharges(values.accesorios);

    
    
    
    
          values.accesoriosList.map(
            item => {
              let accesorioMonto = values.accesorios[item.AccesorioID]
              let accesorioMontoTax = values.accesorios[item.AccesorioID+"_tax"]
              let accesorioMontoTotal = values.accesorios[item.AccesorioID+"_total"]
              let accesorioNombre = item.Etiqueta
    
              compendioAccesorios.push({accesorioNombre, accesorioMonto, accesorioMontoTax, accesorioMontoTotal})
    
            }
          )
    
          const calculateAge = (birthDateString) => {
            const today = new Date();
            const birthDate = new Date(birthDateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
        
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
        
            return age;
        };


        
    
        setSelectedProduct(values.productCred)
    
        const initialDateString =
        values.inicialDate.getFullYear() +
        "-" +
        (values.inicialDate.getMonth() + 1) +
        "-" +
        values.inicialDate.getDate();
    
        const frequencyCapMap = {
          'M': '30',
          'B': '60',
          'T': '90',
          'S': '180',
          'A': '360',
        };
        
    
        const extraData = {
          "type": 'C',
          "payEndAni": 'D',
          "payEndAniInt": 'D',
          "nextAvailDay": 'S',
          "dateAdjustFee": 'N',
          "dateAdjustExiVen": 'N',
          "prospectID": '0',
          "frequencyInt": values.frequencyCap,
          "payFee": frequencyCapMap[values.frequencyCap] ?? '0',
          "payInter": frequencyCapMap[values.frequencyCap] ?? '0',
          "cuotesNumberInt": values.feeNumber,
          "monthDayInt": values.monthDayCap,
          "amountGL": '0',
          "lifeinsuranceChargeFee": 'N',
          "lifeinsuranceIVAChargeFee": 'N',
          "amountLifeinsuranceFee": '0',
          "comClosing": '0'
        }
    
    
    
    
        const finalValues = {...values, ...extraData}
    
        const updatedValues = { ...finalValues, inicialDate: initialDateString, credAmount: values.credAmount, producto: values.productCred, cheques: values.cheques }
    
        var customberNumberNumber = '';
    
        if(values.customerNumber === ''){
           customberNumberNumber = '1';
        }else{
           customberNumberNumber = values.customerNumber;
        }

        
    
        const toSendValues = {
    
          "type": "C",
          "credAmount": Number(values.credAmount) + Number(values.montoRefinanciado),
          "interestRate": Number(values.interestRate),
          "frequencyCap": values.frequencyCap,
          "frequencyInt": values.frequencyCap,
          "payFee": frequencyCapMap[values.frequencyCap] ?? '0',
          "payInter": frequencyCapMap[values.frequencyCap] ?? '0',
          "payEndAni": "D",
          "payEndAniInt": "D",
          "inicialDate": initialDateString,
          "feeNumber": values.feeNumber,
          "cuotesNumberInt": values.feeNumber,
          "productCred": values.productCred,
          "customerNumber": customberNumberNumber,
          "nextAvailDay": "S",
          "dateAdjustFee": "N",
          "dateAdjustExiVen": "N",
          "monthDayInt": values.monthDayCap,
          "monthDayCap": values.monthDayCap,
          "comAper": values.comAper,
          "amountGL": "0",
          "lifeinsuranceChargeFee": "N",
          "lifeinsuranceIVAChargeFee": "N",
          "amountLifeinsuranceFee": "0",
          "termNumber": values.termNumber,
          "prospectID": "0",
          "destCredID": values.destCredID,
          "comClosing": "0",
          "nombreCliente": values.clientName ?? nombreCompletoCliente,
          "Cedula": cedulaIdent,
    
        }

        const quotationData = [
          {
           
          nombreCliente : values.clientName ?? nombreCompletoCliente,
          idCliente : customberNumberNumber,
          montoSolicitado : values.credAmount,
          producto : values.productCred,
          letras : values.feeNumber,
          frecuenciaPago : values.frequencyCap,
          diaMesPago : values.monthDayCap,
          interesAnual : Number(values.interestRate),
          plazo : values.termNumber,
          montoCuota : "",
          accesorios : compendioAccesorios,
          totalAccesorios : "",
          totalMontoFinanciado : Number(values.credAmount) + Number(values.montoRefinanciado) + Number(totalAccesoriosState),
          fechaInicio : initialDateString,
          fechaVencimientoPrimeraCuota : "",
          planDePago : {},
          creditDestination: values.destCredID,
    
          }
    
        ]

        minuta = [{
            Agencia: '',
            NombreDelCliente: values.clientName ?? nombreCompletoCliente,
            FechaDeNacimiento: fechaNacimientoCliente,
            Edad: calculateAge(fechaNacimientoCliente),
            TipoDePersona: values.tipoPersona,
            Tasa: values.interestRate,
            CI_RUC: cedulaIdent,
            SaldoRefinanciado: values.montoRefinanciado,
            InscritoEnAmpyme: '',
            Jubilado: values.jubilado,
            MetodoCalculo: 'Sobre Saldo',
            Producto: values.productCred,
            MontoSolicitado: values.credAmount,
            TasaEfectiva: '',
            SeguroDeVida: values.accesorios && values.accesorios['1'] ? Number(values.accesorios['1']) : 0,
            Plazo: values.termNumber,
            SeguroDeGarantias: values.accesorios && values.accesorios['2'] ? Number(values.accesorios['2']) : 0,
            Letras: values.feeNumber,
            ComisionDelVendedor: values.accesorios && values.accesorios['3'] ? Number(values.accesorios['3']) : 0,
            FrecuenciaDePago: values.frequencyCap,
            MontoTotalSolicitado: Number(values.credAmount),
            MontoTotalFinanciado: Number(values.credAmount) + Number(values.montoRefinanciado),
            ComisionDeCierre: values.accesorios && values.accesorios['4'] ? Number(values.accesorios['4']) : 0,
            Timbres: values.accesorios && values.accesorios['12'] ? Number(values.accesorios['12']) : 0,
            PagoReferido: values.accesorios && values.accesorios['13'] ? Number(values.accesorios['13']) : 0,
            OtrosGastos: values.accesorios && values.accesorios['16'] ? Number(values.accesorios['16']) : 0,
            RetencionSeguroIncendio: values.accesorios && values.accesorios['18'] ? Number(values.accesorios['18']) : 0,
            RetencionSeguroVida: values.accesorios && values.accesorios['19'] ? Number(values.accesorios['19']) : 0,
            SeguroDesgravamen: values.accesorios && values.accesorios['6'] ? Number(values.accesorios['6']) : 0,
            FWLA: values.accesorios && values.accesorios['9'] ? Number(values.accesorios['9']) : 0,
            ComisionSeguroDesgravamen: values.accesorios && values.accesorios['7'] ? Number(values.accesorios['7']) : 0,
            ImpuestoSeguroDesgravamen: values.accesorios && values.accesorios['8'] ? Number(values.accesorios['8']) : 0,
            MemorialExequial: values.accesorios && values.accesorios['10'] ? Number(values.accesorios['10']) : 0,
            ComisionMemorialExequial: values.accesorios && values.accesorios['20'] ? Number(values.accesorios['20']) : 0,
            SeguroISA: values.accesorios && values.accesorios['21'] ? Number(values.accesorios['21']) : 0,
            RetencionSeguroISA: values.accesorios && values.accesorios['22'] ? Number(values.accesorios['22']) : 0,
            RetencionSeguroDeAuto: values.accesorios && values.accesorios['23'] ? Number(values.accesorios['23']) : 0,
            EjecutivoExterno: values.accesorios && values.accesorios['24'] ? Number(values.accesorios['24']) : 0,
            ChequeCertificado: values.accesorios && values.accesorios['11'] ? Number(values.accesorios['11']) : 0,
            GastosLegales: values.accesorios && values.accesorios['5'] ? Number(values.accesorios['5']) : 0,
            MontoADesembolsar: Number(values.credAmount) + Number(values.montoRefinanciado),
            FechaDeDesembolso: initialDateString,
            MontoObligacion: Number(values.credAmount) + Number(values.montoRefinanciado),
            Cheques: values.cheques,
            DestinoCredito: values.destCredID,
            comCierre: values.comCierre,
            accidentesPersonales: values.accesorios && values.accesorios['25'] ? Number(values.accesorios['25']) : 0,
            accidentesPersonalesComision: values.accesorios && values.accesorios['26'] ? Number(values.accesorios['26']) : 0,
            accidentesPersonalesComisionImpuesto: values.accesorios && values.accesorios['26_tax'] ? Number(values.accesorios['26_tax']) : 0,
        }]        
    
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token

        console.log("Payload for /api/v1/safi/simulation:", { safi: toSendValues });

        axios.post(`/api/v1/safi/simulation`, {safi:toSendValues} )
        .then( resp => {
            console.log("Response from /api/v1/safi/simulation:", resp.data);


            const amortizationData = resp.data.amortization;
            const firstPaymentTotal = amortizationData[0].payTotal;
            const fechaPrimerPayment = amortizationData[0].expirationDate;
            const totalIntereses = amortizationData.reduce((acc, item) => acc + parseFloat(item.interest), 0);
            const tasaEfectiva = amortizationData[0].valueCat;

    
          setSimulation(resp.data.amortization);

          const updatedMinuta = {
            ...minuta[0], // Assuming minuta is already an array with one object
            LetraMensual: firstPaymentTotal,
            FechaDePrimerPago: fechaPrimerPayment,
            ITBMS: totalITBMS,
            TotalCargos: totalCharges,
            TotalIntereses: totalIntereses,
            TasaEfectiva: tasaEfectiva
        };

            // Log the payload for save_simulation
            const saveSimulationPayload = {
                ...resp.data, 
                ...{ ClienteID: finalValues.customerNumber },
                ...{ Monto: finalValues.credAmount },
                ...{ Estatus: 'Simulado' },
                ...{ EsCotizacion: 'No' },
                ...{ MontoRefinanciado: values.montoRefinanciado },
                ...{ accesorios: Object.values(updatedValues.accesorios) },
                ...{ allValues: Object.values(updatedValues) },
                ...{ safiValues: Object.values(toSendValues) },
                ...{ quotationData: quotationData },
                minuta: [updatedMinuta] // Send updated minuta with FirstPaymentTotal
            };

            console.log("Payload for /api/v1/safi/save_simulation:", saveSimulationPayload);

          
          // axios.post(`/api/v1/safi/save_simulation`, {safi: {...resp.data, ...{ClienteID:finalValues.customerNumber}} } )
          axios.post(`/api/v1/safi/save_simulation`, {
            
            ...resp.data, 
            ...{ClienteID:finalValues.customerNumber}, 
            ...{Monto:finalValues.credAmount}, 
            ...{Estatus: 'Simulado'}, 
            ...{EsCotizacion: 'No'}, 
            ...{MontoRefinanciado: values.montoRefinanciado},
            ...{accesorios:Object.values(updatedValues.accesorios)}, 
            ...{allValues: Object.values(updatedValues)}, 
            ...{safiValues:Object.values(toSendValues)}, 
            ...{quotationData: quotationData},
            minuta: [updatedMinuta] // Send updated minuta with FirstPaymentTotal

          } )
          .then( resp => {

                
            setSimulacionID(resp.data.result._id.$oid)

    
            let el = document.getElementById('shareone');
            let el2 = document.getElementById('sharetwo');
            let el3 = document.getElementById('sharethree');
            let el4 = document.getElementById('sharefourth');
    
            if(resp.data.result.safiValues['12'] === '140'){
    
              el.removeAttribute("hidden");
              el3.removeAttribute("hidden");
              el4.removeAttribute("hidden");
    
    
            }else{
              el3.removeAttribute("hidden");
              el4.removeAttribute("hidden");
    
            }
    
            
    
          })
          .catch( data => console.log('error', data))
      
          
        })
        .catch( data => console.log('error', data))
    
    
    
    
      }
      



    const handleProductChange = useCallback((value, formik) => {

        formik.setFieldValue('productCred', value);
        // if (value === '140') {
        //     formik.setFieldValue('comCierre', 0.1);
        // }
    
        getPlazos(value, setFrecuencias, setPlazos);
        getAccesorios(formik, value);
    
    }, [getPlazos, getAccesorios]);


    const handleFrequencyChange = (event, formik) => {
        const { value } = event.target;
        formik.setFieldValue('frequencyCap', value);
      
        // Assuming termNumber is already in formik values and formatted like 'XX Meses'
        const termText = formik.values.termNumber;
        const termInMonths = parseInt(termText.replace(/[^\d]/g, ''), 10);
      
        // Define a mapping from frequency value to its corresponding divisor.
        const frequencyDivisors = {
          M: 1,
          B: 2,
          T: 3,
          S: 6
        };
      
        // Calculate the new fee number.
        const feeNumber = termInMonths / (frequencyDivisors[value] || 1);
        formik.setFieldValue('feeNumber', feeNumber);
      
    };


    const optionsDestinos = () => {
        return destinos.map(s => ({
            key: s.DestinoCreID, 
            value: s.Descripcion
        }));
        
    }

    const optionsPlazos = () => {
        return plazos.map(plazo => plazo.Descripcion || "");
      };


    
      

    useEffect(() => {
        setLoading(true);
        getDestinos(setDestinos);
    }
    ,[]);

    return (
        <Formik 
        enableReinitialize={true}
        onSubmit={onSubmit}
        initialValues={{
        cheques: [
            {
                type: '',
                owner: '',
                amount: '',
            }
            ],
        clienteType: '',
        customerNumber: '',
        clientName: '',
        comAper: '',
        totalMontoFinanciado: '',
        productCred: '',
        plazo: '',
        accesoriosList: [],
        accesorios: {},
        montoSeguroDeVida: 0,
        montoRetencionSeguroDeVida: 0,
        montoSeguroDeIncendio: 0,
        montoRetencionSeguroIncendio: 0,
        montoSeguroDeAuto: 0,
        montoSeguroISA: 0,
        montoRetencionSeguroISA: 0,
        montoComisionVendedor: 0,
        montoFWLA: 0,
        montoPagoReferido: 0,
        montoOtrosGastosAccesorios: 0,
        montoRefinanciado: 0,
        accessories: [],
        destCredID: null,
        }}
        >
            {formik => {


                return (
                    <div>
                        <Form>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                        Datos del Cliente
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4" classNameOld="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-2">
                                        <FormikControl
                                            control='select'
                                            label='Tipo de Cliente'
                                            name='clienteType'
                                            options={dropdownClienteType}
                                        />
                                        
                                    </dl>
                                    <dl className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4" classNameOld="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-2">
                                        {formik.values.clienteType === "activo" && (
                                        <div className="flex flex-col items-start">
                                        <div className="flex items-center mb-2">
                                            <FormikControl
                                                control='input'
                                                label='Número de Cliente (SAFI)'
                                                name='customerNumber'
                                                onChange={(e) => {
                                                    if (!isLocked) {
                                                        const value = e.target.value.replace(/\D/g, ''); // Replace any non-digit characters
                                                        formik.setFieldValue('customerNumber', value);
                                                    }
                                                }}
                                                disabled={isLocked}
                                                placeholder="Número de Cliente (SAFI)"
                                                pattern="[0-9]*"
                                            />
                                            <button 
                                                onClick={() => {
                                                    const newIsLocked = !isLocked;
                                                    setIsLocked(newIsLocked); // Toggle the locked state

                                                    if (newIsLocked) {
                                                        // Make the API call when the button is clicked to lock
                                                        ClienteData(formik.values.customerNumber, formik, { setNombreCompletoCliente, setFechaNacimientoCliente, setTipoPersonaCliente, setCedulaIdent });

                                                    } else {
                                                        // Clear the customer name when the button is clicked to unlock
                                                        setNombreCompletoCliente('')
                                                        setFechaNacimientoCliente('')
                                                        setTipoPersonaCliente('')
                                                        setCedulaIdent('')

                                                        formik.setFieldValue('clientName', '');
                                                        formik.setFieldValue('datebirth', '');
                                                        formik.setFieldValue('tipoPersona', '');

                                                    }
                                                }}
                                                className={`mt-5 px-4 text-white rounded-md ${isLocked ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'}`}
                                            >
                                                {isLocked ? (
                                                    <LockClosedIcon className="h-9 w-6 mt-1" />
                                                ) : (
                                                    <LockOpenIcon className="h-9 w-6 mt-1" />
                                                )}
                                            </button>
                                        </div>
                                        <p className="text-sm text-gray-500">
                                            {isLocked ? `Estas evaluando el cliente ${formik.values.customerNumber} debes desbloquear para cambiar el ID` : `Agrega el ID del Cliente - Solo acepta números`}
                                        </p>
                                    </div>

                                        


                                        )}
                                        <FormikControl
                                            control='input'
                                            label='Nombre Completo del Cliente'
                                            name='clientName'
                                            disabled={formik.values.clienteType === 'activo'}
                                        />

                                        <FormikControl
                                            control='date'
                                            label='Fecha de Nacimiento'
                                            name='datebirth'
                                            disabled={formik.values.clienteType === 'activo'}

                                        />

                                        <FormikControl
                                            control='select'
                                            label='Tipo de Persona'
                                            name='tipoPersona'
                                            options={[
                                                { key: 'natural', value: 'Natural' },
                                                { key: 'juridica', value: 'Juridica' }
                                            ]}
                                            disabled={formik.values.clienteType === 'activo'}
                                        /> 
                                        
                                    </dl>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                        Datos de Simulación
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2" className2="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2">

                                    <FormikControl
                                        control='select'
                                        label='Tipo Producto'
                                        name='productCred'
                                        options={dropDownProductOptions}
                                        onChange={(e) => handleProductChange(e.target.value, formik)}
                                    />

                                        <FormikControl
                                            control='select'
                                            label='Destino del Credito'
                                            name='destCredID'
                                            options={optionsDestinos()}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                        /> 

                                    </dl>
                                    <dl className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4" className2="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2">

                                        <FormikControl
                                            control='input'
                                            label='Tasa'
                                            name='interestRate'
                                            type='number'
                                            symbol="%"

                                        />

                                        <FormikControl
                                            control='select'
                                            label='Tipo de Garantía'
                                            name='garantia'
                                            options={dropdownGarantias}
                                        />

                                        {/* <FormikControl
                                            control='select'
                                            label='Plazo'
                                            name='termNumber'
                                            options={optionsPlazos()}
                                        /> */}
                                        {formik.values.productCred && (
                                        <FormikControl
                                            control='selectComplete'
                                            label='Plazo'
                                            name='termNumber'
                                            options={optionsPlazos()}
                                            onChange={(val) => {
                                                const selectedPlazo = plazos.find(plazo => plazo.Descripcion === val);
                                                formik.setFieldValue('termNumber', selectedPlazo ? selectedPlazo.PlazoID : '');
                                            }}
                                            
                                        />
                                        )}

                                        <FormikControl
                                        control='select'
                                        label='Frecuencia de Capital'
                                        name='frequencyCap'
                                        options={dropdownFrecuenciaOptions}
                                        onChange={(e) => handleFrequencyChange(e, formik)}
                                        />

                                        <FormikControl
                                            control='input'
                                            label='Cuotas'
                                            name='feeNumber'
                                            disabled
                                        />

                                        <FormikControl
                                            control='date'
                                            label='Fecha de Inicio del Crédito'
                                            name='inicialDate'
                                        />

                                        <FormikControl
                                            control='input'
                                            label='Monto Solicitado'
                                            name='credAmount'
                                            type='number'
                                            symbol="$"

                                        />

                                        <FormikControl
                                            control='select'
                                            label='Comisión por Cierre'
                                            name='comCierre'
                                            options={dropDownComCierre}
                                        />

                                        <FormikControl
                                            control='input'
                                            label='Monto Refinanciado'
                                            name='montoRefinanciado'
                                            type='number' 
                                            symbol="$"
                                        />

                                        <FormikControl
                                            control='select'
                                            label='Dia Mes Pago'
                                            name='monthDayCap'
                                            options={dropdownDiaMesPagoOptions}
                                        />

                                        <FormikControl
                                            control='select'
                                            label='Cheque Certificado'
                                            name='chequeCert'
                                            options={dropdownCheque}
                                        />

                                        <FormikControl
                                            control='select'
                                            label='Tiene Codeudor que califique para seguro de desgravamen?'
                                            name='codeudores'
                                            options={dropdownCod}
                                        />

                                        { 
                           
                                        formik.values.codeudores === "si" && 
                                        (
                
                                        <FormikControl
                                            control='date'
                                            label='Fecha de Nacimiento Codeudor'
                                            name='datebirthCodeudor'
                                        />    
         
                                        )} 
         
                                        <FormikControl
                                            control='select'
                                            label='El deudor principal es jubilado / pensionado?'
                                            name='jubilado'
                                            options={dropdownJub}
                                        />

                                    </dl>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                        Gastos Legales
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-4 ">

                                    <FormikControl
                            control='select'
                            label='Gasto Legal'
                            name='gastosLegales'
                            options={dropdownGastosLegales}
                          />


                           { 
                           
                           formik.values.gastosLegales === "contPrendarioHipotecaVehiculo" && 
                            (

                              <FormikControl
                                control='select'
                                label='Vehiculo Extra'
                                name='vehiculoExtra1'
                                options={dropdownVehiculos}
                              />
                            )
                            
                            
                          
                          } 

                          { 
                           formik.values.gastosLegales === "contPrendarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='Vehiculo Extra'
                                name='vehiculoExtra2'
                                options={dropdownVehiculos}
                              />
                            )
                          }

                          { 
                           formik.values.gastosLegales === "contInmobiliarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='Vehiculo Extra'
                                name='vehiculoExtra3'
                                options={dropdownVehiculos}
                              />

                              )
                            }

                            { 
                           formik.values.gastosLegales === "contInmobiliarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='Finca Adicional'
                                name='finca1'
                                options={dropdownVehiculos}
                              />

                              )
                            }

                            { 
                           formik.values.gastosLegales === "contInmobiliarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='2da Hipoteca'
                                name='hipoteca1'
                                options={dropdownHipotecas}
                              />

                              )
                            }


                                    </dl>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                        Gastos Legales Especiales
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-4">

                                    <FormikControl
                                        control='select'
                                        label='Alteracion de Turno'
                                        name='alteracionTurno'
                                        options={dropdownHipotecas}
                                    />

                                    <FormikControl
                                        control='select'
                                        label='Firma a Ruego'
                                        name='firmaRuego'
                                        options={dropdownHipotecas}
                                    />

                                    { 
                                
                                    formik.values.firmaRuego === "si" && 
                                    (
                                    <FormikControl
                                        control='select'
                                        label='Cantidad de Firmantes'
                                        name='firmantesExtra1'
                                        options={dropdownVehiculos}
                                    />
                                    )
                                    } 


                                    </dl>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                        Gastos de Seguros
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-4">

                                    <FormikControl
                                        control='input'
                                        label='Seguro de Vida'
                                        name='montoSeguroDeVida'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    <FormikControl
                                        control='input'
                                        label='Retencion Seguro de Vida'
                                        type = 'number'
                                        name='montoRetencionSeguroDeVida'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    <FormikControl
                                        control='input'
                                        label='Seguro de Incendio'
                                        name='montoSeguroDeIncendio'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />

                                    <FormikControl
                                        control='input'
                                        label='Retención Seguro Incendio'
                                        name='montoRetencionSeguroIncendio'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />

                                    <FormikControl
                                        control='input'
                                        label='Seguro de Auto'
                                        name='montoSeguroDeAuto'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />

                                    <FormikControl
                                        control='input'
                                        label='Seguro ISA'
                                        name='montoSeguroISA'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />

                                    <FormikControl
                                        control='input'
                                        label='Retención Seguro ISA'
                                        name='montoRetencionSeguroISA'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    </dl>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                    Comisiones
                                </h3>
                            </div>
                            <div className="px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-4">
                                    <FormikControl
                                        control='select'
                                        label='Comisión del Vendedor'
                                        name='comisionVendedor'
                                        options={[
                                            { key: 'Si', value: 'Si' },
                                            { key: 'No', value: 'No' },
                                        ]}
                                        onChange={(e) => {
                                            formik.setFieldValue('comisionVendedor', e.target.value);
                                            if (e.target.value === 'Si') {
                                                const commissionValue = formik.values.credAmount * 0.01;
                                                formik.setFieldValue('montoComisionVendedor', commissionValue);
                                            } else {
                                                formik.setFieldValue('montoComisionVendedor', 0);
                                            }
                                        }}
                                    />

                                    <FormikControl
                                        control='select'
                                        label='Pago Referido'
                                        name='pagoReferido'
                                        options={[
                                            { key: 'Si', value: 'Si' },
                                            { key: 'No', value: 'No' },
                                        ]}
                                        onChange={(e) => {
                                            formik.setFieldValue('pagoReferido', e.target.value);
                                            if (e.target.value === 'Si') {
                                                formik.setFieldValue('montoPagoReferido', 20);
                                            } else {
                                                formik.setFieldValue('montoPagoReferido', 0);
                                            }
                                        }}
                                    />
                                </dl>
                            </div>
                        </div>




                        <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
      <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
        Cheques
      </h3>
    </div>
    <div className="px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-4">
        {formik.values.cheques.map((cheque, index) => (
          <React.Fragment key={index}>
            <div className="col-span-4">
              <h4 className="text-left text-md leading-5 font-medium text-gray-900">
                Cheque {index + 1}
              </h4>
            </div>
            <FormikControl
              control='select'
              label='Tipo de Cheque'
              name={`cheques[${index}].type`}
              options={[
                { key: 'cliente', value: 'Cliente' },
                { key: 'tercero', value: 'Tercero' },
                { key: 'referido', value: 'Referido (interno o externo)' },

              ]}
            />
            <FormikControl
              control='input'
              label='Nombre del Titular'
              name={`cheques[${index}].owner`}
              type='text'
            />
            <FormikControl
              control='input'
              label='Monto del Cheque'
              name={`cheques[${index}].amount`}
              type='number'
              symbol="$"
            />
            {index === 0 && (
              <div className="flex items-start space-x-2">
                <button
                  type="button"
                  className="mt-6 px-4 py-2 bg-blue-500 text-white rounded h-9"
                  onClick={() => {
                    const newCheque = { type: '', owner: '', amount: '' };
                    formik.setFieldValue('cheques', [...formik.values.cheques, newCheque]);
                  }}
                >
                  +
                </button>
                {formik.values.cheques.length > 1 && (
                  <button
                    type="button"
                    className="mt-6 px-4 py-2 bg-red-500 text-white rounded h-9"
                    onClick={() => {
                      const chequesCopy = [...formik.values.cheques];
                      chequesCopy.pop();
                      formik.setFieldValue('cheques', chequesCopy);
                    }}
                  >
                    -
                  </button>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </dl>
    </div>
</div>


                            {/* <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                        Comisiones
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-3">
                                    <FormikControl
                                        control='input'
                                        label='Comisión del Vendedor'
                                        name='montoComisionVendedor'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />

                                    <FormikControl
                                        control='input'
                                        label='Pago Referido'
                                        name='montoPagoReferido'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    </dl>
                                </div>
                            </div> */}
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                        Otros Accesorios
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-4">


                                    <FormikControl
                                        control='input'
                                        label='F.W.L.A.'
                                        name='montoFWLA'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />


                                    <FormikControl
                                        control='input'
                                        label='Otros Gastos'
                                        name='montoOtrosGastosAccesorios'
                                        type = 'number'
                                        symbol="$"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    </dl>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div  className="px-4 py-5 sm:px-6 flex items-center">
                                    <button
                                        ref={btnRef}
                                        className="bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-orange-500 rounded"
                                        type="button"

                                        onClick={() => {
                                            console.log('fomik***', formik.values);

                                            let gastoLegalMonto = 0;


                                            const birthDate = new Date(formik.values.datebirth);
                            
                                            const seventyYearsAgo = new Date();
                                            seventyYearsAgo.setFullYear(seventyYearsAgo.getFullYear() - 71);
                            
                                            if (birthDate <= seventyYearsAgo && formik.values.clienteType === "nuevo") {
                                                alert("El deudor o codeudor no pueden ser mayores a 70 años.");
                                                return; 
                                            }
                            
                                            if(formik.values.tipoPersona === "juridica"){
                            
                                                gastoLegalMonto = gastoLegalMonto + Number(100)
                            
                                            }
                                                
                                            switch (formik.values.gastosLegales) {
                                                case 'contPrendario':
                                                    gastoLegalMonto += Number(50);
                                                    break;
                                                case 'contEscrituraBienMuebleBPN':
                                                    gastoLegalMonto += Number(350);
                                                    break;
                                                case 'contPrendarioHipotecaVehiculo':
                                                    gastoLegalMonto += Number(125);
                                                    if (formik.values.vehiculoExtra1 > 0) {
                                                        gastoLegalMonto += Number(75) * Number(formik.values.vehiculoExtra1);
                                                    }
                                                    break;
                                                case 'contInmobiliarioEscrituraPublica':
                                                    gastoLegalMonto += Number(650);
                                                    if (formik.values.vehiculoExtra3 > 0) {
                                                        gastoLegalMonto += Number(150) * Number(formik.values.vehiculoExtra3);
                                                    }
                                                    if (formik.values.finca1 > 0) {
                                                        gastoLegalMonto += Number(150) * Number(formik.values.finca1);
                                                    }
                                                    if (formik.values.hipoteca1 === 'si') {
                                                        gastoLegalMonto += Number(100);
                                                    }
                                                    break;
                                                case 'contPrendarioEscrituraPublica':
                                                    gastoLegalMonto += Number(275);
                                                    if (formik.values.vehiculoExtra2 == 1) {
                                                        gastoLegalMonto += Number(75);
                                                    } else if (formik.values.vehiculoExtra2 > 1) {
                                                        let calculation = Number(formik.values.vehiculoExtra2) - 1;
                                                        gastoLegalMonto += Number(75) + (calculation * Number(150));
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                                                    
                                                
                                            if(formik.values.firmaRuego === 'si'){
                            
                                            gastoLegalMonto = gastoLegalMonto + (Number(30) * formik.values.firmantesExtra1)
                            
                                            }
                                            
                                            if(formik.values.alteracionTurno === 'si'){
                            
                                            gastoLegalMonto = gastoLegalMonto + Number(350)
                            
                                            }
                            
                                            gastoLegalMontoImpuesto = gastoLegalMonto * Number(0.07)
                                            gastoLegalConImpuesto = gastoLegalMonto + gastoLegalMontoImpuesto
                            
                                            var sel = document.getElementById("termNumber");

                                            var text = sel.value;
                                            var termInMonths = text.replace(/[^\d]/g, '');
                                            formik.setFieldValue('termInMonths', termInMonths);

                                            const ageDetailsDebtor = calculateAgeDetails(formik.values.datebirth,  Number(termInMonths));
                                            const ageDetailsCodebtor = calculateAgeDetails(formik.values.datebirthCodeudor,  Number(termInMonths));

                                            if(formik.values.chequeCert === 'si'){
                                                chequeCertificadoMonto = Number(10)
                                            }else{
                                                chequeCertificadoMonto = Number(0.00)
                                            }

                                            chequeCertificadoMontoImpuesto = chequeCertificadoMonto * Number(0.07)
                                            chequeCertificadoConImpuesto = chequeCertificadoMonto + chequeCertificadoMontoImpuesto
                                            pagoReferidoMonto = Number(formik.values.montoPagoReferido)
                                            pagoReferidoMontoImpuesto = Number(0.00)
                                            pagoReferidoConImpuesto = pagoReferidoMonto + pagoReferidoMontoImpuesto


                                            let memorialExequialMontoDebtor = calculateExequialExpenses(ageDetailsDebtor.ageStartingCredit,  Number(termInMonths));
                                            let comisionMemorialMontoDebtor = memorialExequialMontoDebtor.totalExequiasConImpuesto - memorialExequialMontoDebtor.memorialExequialConImpuesto;

                                            let memorialExequialMontoCodebtor = {
                                                totalExequiasMonto: 0,
                                                totalExequiasMontoImpuesto: 0,
                                                totalExequiasConImpuesto: 0,
                                                memorialExequialMonto: 0,
                                                memorialExequialMontoImpuesto: 0,
                                                memorialExequialConImpuesto: 0,
                                                comisionMemorialMonto: 0,
                                                comisionMemorialMontoImpuesto: 0,
                                                comisionMemorialConImpuesto: 0,
                                            }
                            
                                            let comisionMemorialMontoCodebtor = 0;

                                            if(formik.values.codeudores === 'si'){
                                                memorialExequialMontoCodebtor = calculateExequialExpenses(ageDetailsCodebtor.ageStartingCredit,  Number(termInMonths));
                                                comisionMemorialMontoCodebtor = memorialExequialMontoCodebtor.totalExequiasConImpuesto - memorialExequialMontoCodebtor.memorialExequialConImpuesto;
                                            }
                                    
                                    
                                            gastoTotalAccesoriosSinCierre = gastoLegalMonto + pagoReferidoMonto + chequeCertificadoMonto + memorialExequialMontoDebtor.memorialExequialConImpuesto + memorialExequialMontoCodebtor.memorialExequialConImpuesto + comisionMemorialMontoDebtor + comisionMemorialMontoCodebtor + Number(formik.values.montoSeguroDeVida)+ Number(formik.values.montoRetencionSeguroDeVida) + Number(formik.values.montoSeguroDeIncendio)+ Number(formik.values.montoRetencionSeguroIncendio) + Number(formik.values.montoSeguroDeAuto) + Number(formik.values.montoSeguroISA) + Number(formik.values.montoRetencionSeguroISA) +  Number(formik.values.montoComisionVendedor) + Number(formik.values.montoFWLA) + Number(formik.values.montoPagoReferido) + Number(formik.values.montoOtrosGastosAccesorios) + ( Number(formik.values.credAmount) * Number(formik.values.comCierre) )


                                            const debtorFactors = calculateFactors(ageDetailsDebtor.finalAge);
                                            const codebtorFactors = ageDetailsCodebtor.finalAge ? calculateFactors(ageDetailsCodebtor.finalAge) : null;

                                            let seguroDesgravamenMontoDebtor = calculateSeguroDesgravamen(
                                                formik.values.credAmount,
                                                formik.values.montoRefinanciado,
                                                gastoTotalAccesoriosSinCierre,
                                                Number(termInMonths),
                                                debtorFactors.factorSeguroDesgravamen,
                                                debtorFactors.factorComisionSeguroDesgravamen,
                                                debtorFactors.factorProyeccionCuotas,
                                                ageDetailsDebtor.finalAge
                                            );

                                            let seguroDesgravamenMontoCodebtor = 0;


                                            if (formik.values.codeudores === 'si') {
                                                seguroDesgravamenMontoCodebtor = calculateSeguroDesgravamen(
                                                    formik.values.credAmount,
                                                    formik.values.montoRefinanciado,
                                                    gastoTotalAccesoriosSinCierre,
                                                    Number(termInMonths),
                                                    codebtorFactors.factorSeguroDesgravamen,
                                                    codebtorFactors.factorComisionSeguroDesgravamen,
                                                    codebtorFactors.factorProyeccionCuotas,
                                                    ageDetailsCodebtor.finalAge
                                                );
                                              }

                                            let timbresFiscalesMonto = calculateTimbresFiscales(formik.values.credAmount, formik.values.montoRefinanciado, ageDetailsDebtor.ageStartingCredit, formik.values.accesorios);

                                            console.log(termInMonths);

                                            let seguroAccidentesMonto = 1.77 * Number(termInMonths);
                                            let seguroAccidentesMontoImpuesto = 0.00;
                                            let seguroAccidentesMontoComision = 3.23 * Number(termInMonths);
                                            let seguroAccidentesMontoComisionImpuesto = (3.23 * Number(termInMonths)) * 0.07;
                                            let seguroAccidentesMontoTotal = 0.00;
                                            let seguroAccidentesMontoComisionTotal = seguroAccidentesMontoComision + seguroAccidentesMontoComisionImpuesto;

                                
                                            if(formik.values.productCred === "150"){
                                                seguroAccidentesMontoTotal = seguroAccidentesMonto + seguroAccidentesMontoComision + seguroAccidentesMontoImpuesto + seguroAccidentesMontoComisionImpuesto;
                                            }else{
                                                seguroAccidentesMonto = 0.00;
                                                seguroAccidentesMontoImpuesto = 0.00;
                                                seguroAccidentesMontoComision = 0.00;
                                                seguroAccidentesMontoComisionImpuesto = 0.00;
                                                seguroAccidentesMontoTotal = 0.00;
                                                seguroAccidentesMontoComisionTotal = 0.00;
                                            }

                                            console.log('seguroAccidentesMonto', seguroAccidentesMonto);
                                            console.log('seguroAccidentesMontoComision', seguroAccidentesMontoComision);
                                            console.log('seguroAccidentesMontoImpuesto', seguroAccidentesMontoImpuesto);
                                            console.log('seguroAccidentesMontoComisionImpuesto', seguroAccidentesMontoComisionImpuesto);
                                            console.log('seguroAccidentesMontoTotal', seguroAccidentesMontoTotal);
                                            console.log('seguroAccidentesMontoComisionTotal', seguroAccidentesMontoComisionTotal);
                
                                            gastoTotalAccesoriosSinDesgravamen = gastoLegalConImpuesto + chequeCertificadoConImpuesto + timbresFiscalesMonto + seguroAccidentesMontoTotal + pagoReferidoConImpuesto + memorialExequialMontoDebtor.memorialExequialConImpuesto + memorialExequialMontoCodebtor.memorialExequialConImpuesto + comisionMemorialMontoDebtor + comisionMemorialMontoCodebtor + comisionPorCierreConImpuesto + Number(formik.values.montoSeguroDeVida) + Number(formik.values.montoRetencionSeguroDeVida) + Number(formik.values.montoSeguroDeIncendio) + Number(formik.values.montoRetencionSeguroIncendio) + Number(formik.values.montoSeguroDeAuto) + Number(formik.values.montoSeguroISA) + Number(formik.values.montoRetencionSeguroISA) +  Number(formik.values.montoComisionVendedor) + Number(formik.values.montoFWLA) + Number(formik.values.montoPagoReferido) + Number(formik.values.montoOtrosGastosAccesorios)


                                            let gastoTotalAccesoriosDebtor = 
                                            Number(gastoLegalConImpuesto) + 
                                            // Number(comisionPorCierreConImpuesto) + 
                                            Number(timbresFiscalesMonto) +
                                            Number(seguroAccidentesMontoTotal) +
                                            Number(seguroDesgravamenMontoDebtor.seguroDesgravamenConImpuesto) +
                                            Number(seguroDesgravamenMontoDebtor.comisionDesgravamenConImpuesto) +
                                            Number(seguroDesgravamenMontoDebtor.impuestoDesgravamenConImpuesto) +
                                            Number(chequeCertificadoConImpuesto) + 
                                            Number(pagoReferidoConImpuesto) + 
                                            Number(memorialExequialMontoDebtor.memorialExequialConImpuesto) +
                                            Number(comisionMemorialMontoDebtor) +
                                            Number(formik.values.montoSeguroDeVida) + 
                                            Number(formik.values.montoRetencionSeguroDeVida) + 
                                            Number(formik.values.montoSeguroDeIncendio) + 
                                            Number(formik.values.montoRetencionSeguroIncendio) + 
                                            Number(formik.values.montoSeguroDeAuto) + 
                                            Number(formik.values.montoSeguroISA) + 
                                            Number(formik.values.montoRetencionSeguroISA) + 
                                            Number(formik.values.montoComisionVendedor) + 
                                            Number(formik.values.montoFWLA) + 
                                            Number(formik.values.montoPagoReferido) + 
                                            Number(formik.values.montoOtrosGastosAccesorios)



                            
                                            let gastoTotalAccesoriosCodebtor = 0;
                                            if (formik.values.codeudores === 'si') {
                                            gastoTotalAccesoriosCodebtor =
                                                memorialExequialMontoCodebtor.memorialExequialConImpuesto +
                                                comisionMemorialMontoCodebtor +
                                                seguroDesgravamenMontoCodebtor.seguroDesgravamenConImpuesto +
                                                seguroDesgravamenMontoCodebtor.comisionDesgravamenConImpuesto + 
                                                seguroDesgravamenMontoCodebtor.impuestoDesgravamenConImpuesto;
                                            }


                                            gastoTotalAccesorios = gastoTotalAccesoriosDebtor + gastoTotalAccesoriosCodebtor;




                                            if(formik.values.jubilado === 'si'){

                                                comisionPorCierre = (Number(formik.values.credAmount) + Number(gastoTotalAccesorios) + Number(formik.values.montoRefinanciado))  * (Number(formik.values.comCierre) / 2)
                          
                                              }else{

                          
                                                comisionPorCierre = (Number(formik.values.credAmount) + Number(gastoTotalAccesorios) + Number(formik.values.montoRefinanciado))  * (Number(formik.values.comCierre))
                          
                                            }
                                              
                                            comisionPorCierreImpuesto = comisionPorCierre * 0.07
                                            comisionPorCierreConImpuesto = comisionPorCierreImpuesto + comisionPorCierre

                                            let gastoTotalAccesoriosFinal =
                                            Number(gastoLegalConImpuesto) + 
                                            Number(comisionPorCierreConImpuesto) + 
                                            Number(timbresFiscalesMonto) +
                                            Number(seguroAccidentesMontoTotal) +
                                            Number(seguroDesgravamenMontoDebtor.seguroDesgravamenMonto)  +
                                            Number(seguroDesgravamenMontoDebtor.comisionDesgravamenConImpuesto)  +
                                            Number(seguroDesgravamenMontoDebtor.impuestoDesgravamenConImpuesto)   +
                                            Number(seguroDesgravamenMontoCodebtor.seguroDesgravamenMonto  || 0)  +
                                            Number(seguroDesgravamenMontoCodebtor.comisionDesgravamenConImpuesto  || 0) +
                                            Number(seguroDesgravamenMontoCodebtor.impuestoDesgravamenConImpuesto  || 0) +

                                            Number(memorialExequialMontoDebtor.memorialExequialConImpuesto) +
                                            Number(comisionMemorialMontoDebtor || 0) +
                                            Number(memorialExequialMontoCodebtor.memorialExequialConImpuesto) +
                                            Number(comisionMemorialMontoCodebtor || 0) +

                                            Number(memorialExequialMontoDebtor.comisionMemorialMontoImpuesto) +
                                            Number(memorialExequialMontoCodebtor.comisionMemorialMontoImpuesto) +

                                            Number(chequeCertificadoConImpuesto) + 
                                            Number(formik.values.montoSeguroDeVida) + 
                                            Number(formik.values.montoRetencionSeguroDeVida) + 
                                            Number(formik.values.montoSeguroDeIncendio) + 
                                            Number(formik.values.montoRetencionSeguroIncendio) + 
                                            Number(formik.values.montoSeguroDeAuto) + 
                                            Number(formik.values.montoSeguroISA) + 
                                            Number(formik.values.montoRetencionSeguroISA) + 
                                            Number(formik.values.montoComisionVendedor) + 
                                            Number(formik.values.montoFWLA) + 
                                            Number(pagoReferidoConImpuesto) + 
                                            Number(formik.values.montoOtrosGastosAccesorios)



                                            totalMontoFinanciadoMonto = Number(formik.values.credAmount) + Number(gastoTotalAccesoriosFinal) + Number(formik.values.montoRefinanciado)





                                            formik.setFieldValue('accesorios.1', Number(formik.values.montoSeguroDeVida).toFixed(2))
                                            formik.setFieldValue('accesorios.1_tax', 0.00)
                                            formik.setFieldValue('accesorios.1_total', Number(formik.values.montoSeguroDeVida).toFixed(2))

                                            formik.setFieldValue('accesorios.3', Number(formik.values.montoComisionVendedor).toFixed(2))
                                            formik.setFieldValue('accesorios.3_tax', "0.00");
                                            formik.setFieldValue('accesorios.3_total', Number(formik.values.montoComisionVendedor).toFixed(2))

                                    
                                            
                                            formik.setFieldValue('accesorios.4', comisionPorCierre.toFixed(2))
                                            formik.setFieldValue('accesorios.4_tax', comisionPorCierreImpuesto.toFixed(2))
                                            formik.setFieldValue('accesorios.4_total', Number(comisionPorCierreConImpuesto.toFixed(2)))
                                            
                                            
                                            formik.setFieldValue('accesorios.5', gastoLegalMonto.toFixed(2))
                                            formik.setFieldValue('accesorios.5_tax', gastoLegalMontoImpuesto.toFixed(2))
                                            formik.setFieldValue('accesorios.5_total', gastoLegalConImpuesto.toFixed(2))
                    
                                            formik.setFieldValue('accesorios.6', 
                                            (
                                                seguroDesgravamenMontoDebtor.seguroDesgravamenMonto + 
                                                (seguroDesgravamenMontoCodebtor?.seguroDesgravamenMonto || 0)
                                            ).toFixed(2)
                                            );
                  
                                            formik.setFieldValue('accesorios.6_tax', 
                                            (
                                                seguroDesgravamenMontoDebtor.seguroDesgravamenMontoImpuesto + 
                                                (seguroDesgravamenMontoCodebtor?.seguroDesgravamenMontoImpuesto || 0)
                                            ).toFixed(2)
                                            );
                  
                                            formik.setFieldValue('accesorios.6_total', 
                                            (
                                                seguroDesgravamenMontoDebtor.seguroDesgravamenConImpuesto + 
                                                (seguroDesgravamenMontoCodebtor?.seguroDesgravamenConImpuesto || 0)
                                            ).toFixed(2)
                                            );
              
                                            formik.setFieldValue('accesorios.7', 
                                            (
                                                seguroDesgravamenMontoDebtor.comisionDesgravamenMonto + 
                                                (seguroDesgravamenMontoCodebtor?.comisionDesgravamenMonto || 0)
                                            ).toFixed(2)
                                            );

                                            formik.setFieldValue('accesorios.7_tax', 
                                            (
                                                seguroDesgravamenMontoDebtor.comisionDesgravamenMontoImpuesto + 
                                                (seguroDesgravamenMontoCodebtor?.comisionDesgravamenMontoImpuesto || 0)
                                            ).toFixed(2)
                                            );

                                            formik.setFieldValue('accesorios.7_total', 
                                            (
                                            seguroDesgravamenMontoDebtor.comisionDesgravamenConImpuesto + 
                                            (seguroDesgravamenMontoCodebtor?.comisionDesgravamenConImpuesto || 0)
                                            ).toFixed(2)
                                            );

                                            formik.setFieldValue('accesorios.8', 
                                            (
                                                seguroDesgravamenMontoDebtor.impuestoDesgravamenMonto + 
                                                (seguroDesgravamenMontoCodebtor ? seguroDesgravamenMontoCodebtor.impuestoDesgravamenMonto : 0)
                                            ).toFixed(2)
                                            );

                                            formik.setFieldValue('accesorios.8_tax', "0.00");

                                            formik.setFieldValue('accesorios.8_total', 
                                            (
                                                seguroDesgravamenMontoDebtor.impuestoDesgravamenConImpuesto + 
                                                (seguroDesgravamenMontoCodebtor?.impuestoDesgravamenConImpuesto || 0)
                                            ).toFixed(2)
                                            );
                    
                                            formik.setFieldValue('accesorios.9', Number(formik.values.montoFWLA).toFixed(2))
                                            formik.setFieldValue('accesorios.9_tax', 0.00)
                                            formik.setFieldValue('accesorios.9_total', Number(formik.values.montoFWLA).toFixed(2))


                                            formik.setFieldValue('accesorios.10', 
                                            (
                                                memorialExequialMontoDebtor.memorialExequialMonto + 
                                                (memorialExequialMontoCodebtor?.memorialExequialMonto || 0)
                                            ).toFixed(2)
                                            );
                  
                                            formik.setFieldValue('accesorios.10_tax', 
                                            (
                                                memorialExequialMontoDebtor.memorialExequialMontoImpuesto + 
                                                (memorialExequialMontoCodebtor?.memorialExequialMontoImpuesto || 0)
                                            ).toFixed(2)
                                            );
                
                                            formik.setFieldValue('accesorios.10_total', 
                                            (
                                                memorialExequialMontoDebtor.memorialExequialConImpuesto + 
                                                (memorialExequialMontoCodebtor?.memorialExequialConImpuesto || 0)
                                            ).toFixed(2)
                                            );
                    
                                            formik.setFieldValue('accesorios.11', chequeCertificadoMonto.toFixed(2))
                                            formik.setFieldValue('accesorios.11_tax', chequeCertificadoMontoImpuesto.toFixed(2))
                                            formik.setFieldValue('accesorios.11_total', chequeCertificadoConImpuesto.toFixed(2))
                                            
                                            formik.setFieldValue('accesorios.12', timbresFiscalesMonto.toFixed(2))
                                            formik.setFieldValue('accesorios.12_tax', 0.00)
                                            formik.setFieldValue('accesorios.12_total', timbresFiscalesMonto.toFixed(2))
                                            
                                            formik.setFieldValue('accesorios.13', pagoReferidoMonto.toFixed(2))
                                            formik.setFieldValue('accesorios.13_tax', pagoReferidoMontoImpuesto.toFixed(2))
                                            formik.setFieldValue('accesorios.13_total', pagoReferidoConImpuesto.toFixed(2))
                                            
                                            
                                            formik.setFieldValue('accesorios.15', Number(formik.values.montoSeguroDeIncendio).toFixed(2))
                                            formik.setFieldValue('accesorios.15_tax', 0.00)
                                            formik.setFieldValue('accesorios.15_total', Number(formik.values.montoSeguroDeIncendio).toFixed(2))
                                            
                                            formik.setFieldValue('accesorios.16', Number(formik.values.montoOtrosGastosAccesorios).toFixed(2))
                                            formik.setFieldValue('accesorios.16_tax', 0.00)
                                            formik.setFieldValue('accesorios.16_total', Number(formik.values.montoOtrosGastosAccesorios).toFixed(2))
                                            
                                            formik.setFieldValue('accesorios.17', Number(formik.values.montoSeguroDeAuto).toFixed(2))
                                            formik.setFieldValue('accesorios.17_tax', 0.00)
                                            formik.setFieldValue('accesorios.17_total', Number(formik.values.montoSeguroDeAuto).toFixed(2))
                                            
                                            formik.setFieldValue('accesorios.19', Number(formik.values.montoRetencionSeguroDeVida).toFixed(2) || 0.00)
                                            formik.setFieldValue('accesorios.19_tax',0.00)
                                            formik.setFieldValue('accesorios.19_total', Number(formik.values.montoRetencionSeguroDeVida).toFixed(2))
                                            
                                            formik.setFieldValue('accesorios.18', Number(formik.values.montoRetencionSeguroIncendio).toFixed(2))
                                            formik.setFieldValue('accesorios.18_tax', 0.00)
                                            formik.setFieldValue('accesorios.18_total', Number(formik.values.montoRetencionSeguroIncendio).toFixed(2))

                                            formik.setFieldValue('accesorios.20', 
                                            (
                                                memorialExequialMontoDebtor.comisionMemorialMonto + 
                                                (memorialExequialMontoCodebtor?.comisionMemorialMonto || 0)
                                            ).toFixed(2)
                                            );
                                        
                                            formik.setFieldValue('accesorios.20_tax', 
                                            (
                                                memorialExequialMontoDebtor.comisionMemorialMontoImpuesto + 
                                                (memorialExequialMontoCodebtor?.comisionMemorialMontoImpuesto || 0)
                                            ).toFixed(2)
                                            );
                                        
                                            formik.setFieldValue('accesorios.20_total', 
                                            (
                                                memorialExequialMontoDebtor.comisionMemorialConImpuesto + 
                                                (memorialExequialMontoCodebtor?.comisionMemorialConImpuesto || 0)
                                            ).toFixed(2)
                                            );
              
                    
                    
                                            formik.setFieldValue('accesorios.21', Number(formik.values.montoSeguroISA).toFixed(2))
                                            formik.setFieldValue('accesorios.21_tax', 0.00)
                                            formik.setFieldValue('accesorios.21_total', Number(formik.values.montoSeguroISA).toFixed(2))
                                            
                                            
                                            formik.setFieldValue('accesorios.22', Number(formik.values.montoRetencionSeguroISA).toFixed(2))
                                            formik.setFieldValue('accesorios.22_tax', 0.00)
                                            formik.setFieldValue('accesorios.22_total', Number(formik.values.montoRetencionSeguroISA).toFixed(2))

                                            formik.setFieldValue('accesorios.25', seguroAccidentesMonto.toFixed(2))
                                            formik.setFieldValue('accesorios.25_tax', 0.00)
                                            formik.setFieldValue('accesorios.25_total', seguroAccidentesMonto.toFixed(2))

                                            formik.setFieldValue('accesorios.26', seguroAccidentesMontoComision.toFixed(2))
                                            formik.setFieldValue('accesorios.26_tax', seguroAccidentesMontoComisionImpuesto.toFixed(2))
                                            formik.setFieldValue('accesorios.26_total', seguroAccidentesMontoComisionTotal.toFixed(2))


                                            formik.setFieldValue('totalMontoFinanciado', totalMontoFinanciadoMonto.toFixed(2))

                                            setTotalAccesorios(gastoTotalAccesoriosFinal)


                                            formik.setFieldValue('comAper', Number(gastoTotalAccesoriosFinal.toFixed(2)))



















                                        }}
                                    >
                                        Calcular Accesorios
                                    </button>
                                    <div className="ml-4 text-lg"> {/* Additional styling can be adjusted as needed */}
                                    <span>Total Accesorios: ${formik.values.comAper}</span>
<span className="ml-4">Total Monto Financiado: ${formik.values.totalMontoFinanciado}</span>
 {/* Example static value */}
                                    </div>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Resumen De Accesorios
                                    </h3>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <div className="flex flex-col">
                                        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                                            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                                                <table className="min-w-full">
                                                    <thead>
                                                        <tr>
                                                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                                Accesorio
                                                            </th>
                                                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                                Monto Accesorio
                                                            </th>
                                                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                                Impuesto (ITBMS)
                                                            </th>
                                                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                                TOTAL
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                    { formik.values.accesoriosList?.map( acc => {
                                
                                                        return(
                                                        
                                                        <tr key={acc["AccesorioID"]} className="bg-white">
                                                        
                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                            {acc["Etiqueta"]}
                                                            
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                            <FormikControl
                                                                control='input'
                                                                label={` `}
                                                                name={`accesorios.${acc["AccesorioID"]}`}
                                                            />
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                            <FormikControl
                                                                control='input'
                                                                label={` `}
                                                                name={`accesorios.${acc["AccesorioID"]}`+`_tax`}
                                                            />
                                                            
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                            <FormikControl
                                                                control='input'
                                                                label={` `}
                                                                name={`accesorios.${acc["AccesorioID"]}`+`_total`}
                                                            /> 
                                                            </td>
                                                        </tr>   
                                                        )
                                                    })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                    Totales
                                </h3>
                            </div>
                            <div className="px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-8 sm:grid-cols-12">
                                    <div className="sm:col-span-6"> 
                                        <FormikControl
                                            control='input'
                                            label='Total Accesorios'
                                            name='comAper'
                                            disabled
                                        />
                                    </div>
                                    <div className="sm:col-span-6">
                                        <FormikControl
                                            control='input'
                                            label='Total Monto Financiado'
                                            name='totalMontoFinanciado'
                                            disabled
                                        />
                                    </div>
                                </dl>
                            </div>
                        </div>

                            <div className="mx-6 mt-6 bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <ButtonsSimulacion />
                                </div>
                            </div>

                            <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
                            <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                                Amortizaciones Simuladas
                                </h3>
                            </div>
                                <TablaSimulacion amortization={simulation} />
                            </div>

                            <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              
                                    <div className="px-4 py-5 sm:px-6">
                                    <ButtonsShare simulacionID={simulacionID}/>  

                                        </div>

                            </div>



                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default DatosSimulacion;