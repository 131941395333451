import React, {useEffect,useState, useRef, memo} from "react";
import { Formik, Form } from 'formik';
import FormikControl from '../forms/FormikControl';
import axios from 'axios';
import { calculateAgeDetails } from "./AgeDetails";
import { calculateFactors } from "./Factors";
import { calculateTimbresFiscales } from "./Timbres";
import { calculateExequialExpenses } from "./Exequial";
import { calculateSeguroDesgravamen } from "./Desgravamen";
import { ButtonsShare } from "./ButtonsShare";
import { ButtonsSimulacion } from "./ButtonsSimulacion";
import { TablaSimulacion } from "./TablaSimulacion"




const DatosSimulacion = (props) => {

  let gastoLegalMonto = Number(0.00);
  let gastoLegalMontoImpuesto = Number(0.00);
  let gastoLegalConImpuesto = Number(0.00);
  let gastoTotalAccesorios = Number(0.00);
  let comisionPorCierre = Number(0.00);
  let comisionPorCierreImpuesto = Number(0.00);
  let comisionPorCierreConImpuesto = Number(0.00);

  let gastoTotalAccesoriosSinCierre = Number(0.00);
  let timbresFiscales = Number(0.00);
  let termInMonths = Number(0.00);
  let seguroDesgravamenMonto = Number(0.00);
  let seguroDesgravamenMontoImpuesto = Number(0.00);
  let seguroDesgravamenConImpuesto = Number(0.00);

  let factorSeguroDesgravamen = Number(0.00);
  let factorComisionSeguroDesgravamen = Number(0.00);


  let comisionDesgravamenMonto = Number(0.00);
  let comisionDesgravamenMontoImpuesto = Number(0.00);
  let comisionDesgravamenConImpuesto = Number(0.00);

  let impuestoDesgravamenMonto = Number(0.00);
  let impuestoDesgravamenMontoImpuesto = Number(0.00);
  let impuestoDesgravamenConImpuesto = Number(0.00);

  let chequeCertificadoMonto = Number(0.00);
  let chequeCertificadoMontoImpuesto = Number(0.00);
  let chequeCertificadoConImpuesto = Number(0.00);

  let pagoReferidoMonto = Number(0.00);
  let pagoReferidoMontoImpuesto = Number(0.00);
  let pagoReferidoConImpuesto = Number(0.00);

  let totalExequiasMonto = Number(0.00);
  let totalExequiasMontoImpuesto = Number(0.00);
  let totalExequiasConImpuesto = Number(0.00);

  let memorialExequialMonto = Number(0.00);
  let memorialExequialMontoImpuesto = Number(0.00);
  let memorialExequialConImpuesto = Number(0.00);

  let comisionMemorialMonto = Number(0.00);
  let comisionMemorialMontoImpuesto = Number(0.00);
  let comisionMemorialConImpuesto = Number(0.00);

  let accesorio1 = Number(0.00);
  let accesorio6 = Number(0.00);
  let accesorio11 = Number(0.00);

  let totalMontoFinanciadoMonto = Number(0.00);
  let gastoTotalAccesoriosSinDesgravamen = Number(0.00);



  

  const ClienteData = (id) => {


    axios.get(`/api/v1/clientes2/${id}`,
    ).then(res => {

      setNombreCompletoCliente(res.data.client.NombreCompleto)

    
    });

  

    
                    

  }

  

  

  

  

  const [simulationParams, setSimulationParams] = useState(
    {
      "chequeCert": "no",
      "alteracionTurno" : "no",
      "firmaRuego": "no",
      "type": "",
      "montoRefinanciado": 0,
      "credAmount": "",
      "interestRate": "",
      "frequencyCap": "",
      "frequencyInt": "",
      "payFee": "",
      "payInter": "",
      "payEndAni": "",
      "payEndAniInt": "",
      "inicialDate": "",
      "feeNumber": "",
      "cuotesNumberInt": "",
      "productCred": "",
      "customerNumber": "",
      "nextAvailDay": "",
      "dateAdjustFee": "",
      "dateAdjustExiVen": "",
      "monthDayInt": "",
      "monthDayCap": "",
      "comAper": "",
      "amountGL": "",
      "lifeinsuranceChargeFee": "",
      "lifeinsuranceIVAChargeFee": "",
      "amountLifeinsuranceFee": "",
      "termNumber": "",
      "prospectID": "",
      "destCredID": "",
      "comClosing": "",
      "sumAccesorios": 20,
      "accesorios": {},
      "accesoriosList": [],
      "montoSeguroDeVida": Number(0.00),
      "montoRetencionSeguroDeVida": Number(0.00),
      "montoSeguroDeIncendio": Number(0.00),
      "montoRetencionSeguroIncendio": Number(0.00),
      "montoSeguroDeAuto": Number(0.00),
      "montoSeguroISA": Number(0.00),
      "montoRetencionSeguroISA": Number(0.00),
      "montoComisionVendedor": Number(0.00),
      "montoFWLA": Number(0.00),
      "montoPagoReferido": Number(0.00),
      "montoOtrosGastosAccesorios": Number(0.00)
      

    }      
  );
  const [simulation, setSimulation] = useState([]);
  const [plazos, setPlazos] = useState([]);
  const [frecuencias, setFrecuencias] = useState([]);

  const [destinos, setDestinos] = useState([]);

  const [simulacionID, setSimulacionID] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [accesorios, setAccesorios] = useState([]);

  const [totalCredito, setTotalCredito] = useState(0);

  const [totalAccesoriosState, setTotalAccesorios] = useState(0);
  const [gastoLegal, setGastoLegal] = useState([]);
  //const [gastoLegalMonto, setGastoLegalMonto] = useState([]);

  const [firmaRuego, setFirmaRuego] = useState(0);
  const [tipoPersona, setTipoPersona] = useState(0);
  const [contratoPrendario, setContratoPrendario] = useState(0);

  const [edadCliente, setEdadCliente] = useState([]);


  const [loading, setLoading] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const [calculated, setCalculated] = useState(0);


  const [seguroDesgravamen, setSeguroDesgravamen] = useState(0);

  const [nombreCompletoCliente, setNombreCompletoCliente] = useState([]);
  const [nombreCompletoUsuario, setNombreCompletoUsuario] = useState([]);

  const [accesoriosCotizacion, setAccesoriosCotizacion] = useState([]);


  


  //Accesorios
  const [accesorio4, setAccesorio4] = useState(0);

  function percentage(num, per)
  {
    return (num/100)*per;
  }

  const handleClickParaCliente = (simulacionID) => {
    window.open('https://www.sumafinanciera.com/landing/index.php?_id='+simulacionID);
   //window.open('http://localhost:8888/print_oferta.php?_id='+simulacionID);
  };





  const updateCotizacion = (cotizaID) => {
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    //alert(simulacionID.$oid)compartida
    axios.post(`/api/v1/safi/update_simulation`, {...{cotizaID}, ...{Estatus: 'Cotizacion Compartida'}} )
      .then( resp => {
        //console.log('response',resp);
        navigator.clipboard.writeText('https://www.sumafinanciera.com/landing/index.php?_id='+cotizaID)

      })
      .catch( data => console.log('error', data))
  }


  const crearCotizacion = (cotizaID) => {
    //alert(simulacionID.$oid)
    axios.post(`/api/v1/safi/update_simulation`, {...{cotizaID}, ...{Estatus: 'Cotizacion Creada'}, ...{EsCotizacion: 'Si'}} )
      .then( resp => {
        //console.log('response',resp);
        //setSimulacionID(resp.data.result._id.$oid)

      })
      .catch( data => console.log('error', data))
  }

  const eliminarCotizacion = () => {
    alert('Cookie Dough')
  }


  const getPlazos = (productoID) => {
      const token = document.querySelector('[name=csrf-token]').content
      axios.defaults.headers.common['X-CSRF-TOKEN'] = token
      axios.get(`/api/v1/plazos.json?productoid=${productoID}`)
      .then( resp => {

         const plazosid = resp.data[0].PlazoID
         setFrecuencias((resp.data[0].Frecuencias).split(','))
         axios.get(`/api/v1/plazosyfrecuencias.json?plazosid=${plazosid}`)
         .then( resp => {

          //console.log(resp.data)

          const sortedArray = Array.from(resp.data).sort((item1, item2) => {
            const [item1DescValue1, item1DescValue2] = item1.Descripcion.split(" ");
            const [item2DescValue1, item2DescValue2] = item2.Descripcion.split(" ");
          
            return Number(item2DescValue1) < Number(item1DescValue1);
          })
        
          setPlazos(sortedArray);

         })
         .catch(error => {return error});
          
          
      })
      .catch( error => {return error}); 

  };

  const getDestinos = () => {
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    axios.get(`/api/v1/destinos`)
    .then( resp => {
        //console.log(`destinos`, resp.data);
        setDestinos(resp.data);
        
    })
    .catch( error => {return error}); 

};



const getAccesorios = (formik, productoID) => {
  const token = document.querySelector('[name=csrf-token]').content
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token
  axios.get(`/api/v1/accesorios?productoid=${productoID}`)
  .then( resp => {
    // setAccesorios(resp.data.accesorios);

    //console.log(`accesoriosList`, resp.data.accesorios)
    //console.log(`accesorios`, resp.data.values)

    let test = resp.data.accesorios.sort((a, b) => (a.OrdenCotizacion > b.OrdenCotizacion) ? 1 : -1)

    //console.log(test)



    formik.setFieldValue('accesoriosList', resp.data.accesorios);
    formik.setFieldValue('accesorios', resp.data.values);

  })
  .catch( error => {return error}); 

};

const sumAccesorios = (formik) => {
  formik.setFieldValue(
    'sumAccesorios',
    Object.values(formik.values.accesorios).reduce((a, b) => parseFloat(a) + parseFloat(b) )
  );
  formik.setFieldValue(
    'comAper',
    Object.values(formik.values.accesorios).reduce((a, b) => parseFloat(a) + parseFloat(b) )
  );


};

  const optionsPlazos = () => {
    return plazos.map(s => ({
        key: s.PlazoID, 
        value: s.Descripcion
    }));
}


const optionsFrecuencias = () => {

  return frecuencias.map(s => ({ 
      key: s ,
      value: s 
  }));
}


const optionsDestinos = () => {
  return destinos.map(s => ({
      key: s.DestinoCreID, 
      value: s.Descripcion
  }));
  
}




  const onSubmit = (values) => {

    var compendioAccesorios = []
    let compendioObject = {}




      values.accesoriosList.map(
        item => {
          let accesorioMonto = values.accesorios[item.AccesorioID]
          let accesorioMontoTax = values.accesorios[item.AccesorioID+"_tax"]
          let accesorioMontoTotal = values.accesorios[item.AccesorioID+"_total"]
          let accesorioNombre = item.Etiqueta

          compendioAccesorios.push({accesorioNombre, accesorioMonto, accesorioMontoTax, accesorioMontoTotal})

        }
      )


    

    setSelectedProduct(values.productCred)

    const initialDateString =
    values.inicialDate.getFullYear() +
    "-" +
    (values.inicialDate.getMonth() + 1) +
    "-" +
    values.inicialDate.getDate();

    const frequencyCapMap = {
      'M': '30',
      'B': '60',
      'T': '90',
      'S': '180',
      'A': '360',
    };
    

    const extraData = {
      "type": 'C',
      "payEndAni": 'D',
      "payEndAniInt": 'D',
      "nextAvailDay": 'S',
      "dateAdjustFee": 'N',
      "dateAdjustExiVen": 'N',
      "prospectID": '0',
      "frequencyInt": values.frequencyCap,
      "payFee": frequencyCapMap[values.frequencyCap] ?? '0',
      "payInter": frequencyCapMap[values.frequencyCap] ?? '0',
      "cuotesNumberInt": values.feeNumber,
      "monthDayInt": values.monthDayCap,
      "amountGL": '0',
      "lifeinsuranceChargeFee": 'N',
      "lifeinsuranceIVAChargeFee": 'N',
      "amountLifeinsuranceFee": '0',
      "comClosing": '0'
    }




    const finalValues = {...values, ...extraData}

    const updatedValues = { ...finalValues, inicialDate: initialDateString, credAmount: values.credAmount, producto: values.productCred }

    var customberNumberNumber = '';

    if(values.customerNumber === ''){
       customberNumberNumber = '1';
    }else{
       customberNumberNumber = values.customerNumber;
    }

    const toSendValues = {

      "type": "C",
      "credAmount": Number(values.credAmount) + Number(totalAccesoriosState) + Number(values.montoRefinanciado),
      "interestRate": Number(values.interestRate),
      "frequencyCap": values.frequencyCap,
      "frequencyInt": values.frequencyCap,
      "payFee": frequencyCapMap[values.frequencyCap] ?? '0',
      "payInter": frequencyCapMap[values.frequencyCap] ?? '0',
      "payEndAni": "D",
      "payEndAniInt": "D",
      "inicialDate": initialDateString,
      "feeNumber": values.feeNumber,
      "cuotesNumberInt": values.feeNumber,
      "productCred": values.productCred,
      "customerNumber": customberNumberNumber,
      "nextAvailDay": "S",
      "dateAdjustFee": "N",
      "dateAdjustExiVen": "N",
      "monthDayInt": values.monthDayCap,
      "monthDayCap": values.monthDayCap,
      "comAper": values.comAper,
      "amountGL": "0",
      "lifeinsuranceChargeFee": "N",
      "lifeinsuranceIVAChargeFee": "N",
      "amountLifeinsuranceFee": "0",
      "termNumber": values.termNumber,
      "prospectID": "0",
      "destCredID": values.destCredID,
      "comClosing": "0",
      "nombreCliente": values.clientName ?? nombreCompletoCliente

    }

    const quotationData = [
      {
       
      nombreCliente : values.clientName ?? nombreCompletoCliente,
      idCliente : customberNumberNumber,
      montoSolicitado : values.credAmount,
      producto : values.productCred,
      letras : values.feeNumber,
      frecuenciaPago : values.frequencyCap,
      diaMesPago : values.monthDayCap,
      interesAnual : Number(values.interestRate),
      plazo : values.termNumber,
      montoCuota : "",
      accesorios : compendioAccesorios,
      totalAccesorios : "",
      totalMontoFinanciado : Number(values.credAmount) + Number(values.montoRefinanciado) + Number(totalAccesoriosState),
      fechaInicio : initialDateString,
      fechaVencimientoPrimeraCuota : "",
      planDePago : {},
      creditDestination: values.destCredID,

      }

    ]



    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    axios.post(`/api/v1/safi/simulation`, {safi:toSendValues} )
    .then( resp => {

      setSimulation(resp.data.amortization);
      // axios.post(`/api/v1/safi/save_simulation`, {safi: {...resp.data, ...{ClienteID:finalValues.customerNumber}} } )
      axios.post(`/api/v1/safi/save_simulation`, {
        ...resp.data, 
        ...{ClienteID:finalValues.customerNumber}, 
        ...{Monto:finalValues.credAmount}, 
        ...{Estatus: 'Simulado'}, 
        ...{EsCotizacion: 'No'}, 
        ...{MontoRefinanciado: values.montoRefinanciado},
        ...{accesorios:Object.values(updatedValues.accesorios)}, 
        ...{allValues: Object.values(updatedValues)}, 
        ...{safiValues:Object.values(toSendValues)}, 
        ...{quotationData: quotationData}
      } )
      .then( resp => {

        setSimulacionID(resp.data.result._id.$oid)

        let el = document.getElementById('shareone');
        let el2 = document.getElementById('sharetwo');
        let el3 = document.getElementById('sharethree');
        let el4 = document.getElementById('sharefourth');

        if(resp.data.result.safiValues['12'] === '140'){

          el.removeAttribute("hidden");
          el3.removeAttribute("hidden");
          el4.removeAttribute("hidden");


        }else{
          el3.removeAttribute("hidden");
          el4.removeAttribute("hidden");

        }

        

      })
      .catch( data => console.log('error', data))
  
      
    })
    .catch( data => console.log('error', data))




  }

    useEffect(() => {
          setLoading(true);
          //getPlazos();
          getDestinos();
    }, []); 


  const dropdownFrecuenciaOptions = [
    { key: 'M', value: 'Mensual' },
    { key: 'B', value: 'Bimestral' },
    { key: 'T', value: 'Trimestral' },
    { key: 'S', value: 'Semestral' }
  ]

  const dropdownGarantias = [
    { key: 'prendaria', value: 'Prendaria' },
    { key: 'mobiliaria', value: 'Mobiliaria' },
    { key: 'semoviente', value: 'Semoviente' },
    { key: 'inmobiliaria', value: 'Inmobiliaria' },
    { key: 'otras', value: 'Otras' },
    { key: 'singarantias', value: 'Sin Garantias' }
  ]

  const dropdownGastosLegales = [
    { key: 'contPrendario', value: 'Contrato Prendario (Incluye Semovientes) hasta $5000' },
    { key: 'contPrendarioHipotecaVehiculo', value: 'Contrato Prendario hasta $5000 + Hipoteca Vehiculo' },
    { key: 'contPrendarioEscrituraPublica', value: 'Contrato Prendario mas de $5000 (Escritura Publica)' },
    { key: 'contInmobiliarioEscrituraPublica', value: 'Contrato Inmobiliario mas de $5000 (Escritura Publica)' },
    { key: 'contEscrituraBienMuebleBPN', value: 'Contrato Escritura Bien Mueble Alivio BNP' }
  ]


  const dropdownDiaMesPagoOptions = [
      { key: '1',value: '1' },
      { key: '2',value: '2' },
      { key: '3',value: '3' },
      { key: '4',value: '4' },
      { key: '5',value: '5' },
      { key: '6',value: '6' },
      { key: '7',value: '7' },
      { key: '8',value: '8' },
      { key: '9',value: '9' },
      { key: '10',value: '10' },
      { key: '11',value: '11' },
      { key: '12',value: '12' },
      { key: '13',value: '13' },
      { key: '14',value: '14' },
      { key: '15',value: '15' },
      { key: '16',value: '16' },
      { key: '17',value: '17' },
      { key: '18',value: '18' },
      { key: '19',value: '19' },
      { key: '20',value: '20' },
      { key: '21',value: '21' }
  ]

  const dropDownProductOptions = [
    { key: '10', value: 'SUMA CONFIANZA' },
    { key: '20', value: 'SUMA SOBRE RUEDAS' },
    { key: '21', value: 'SUMA SOBRE RUEDAS' },
    { key: '30', value: 'SUMA MAQUINARIA (EXISTENTE)' },
    { key: '31', value: 'SUMA MAQUINARIA (COMPRA)' },
    { key: '150', value: 'SUMA MUJER' },

    { key: '40', value: 'SUMA EMPRESARIOS' },
    { key: '50', value: 'SUMA CONSUMO FINANC. POLIZAS SEGURO' },
    { key: '60', value: 'SUMA XPRESS' },
    { key: '61', value: 'SUMA XPRESS XTRA' },
    { key: '70', value: 'SUMA PERSONAS' },


    { key: '100', value: 'SUMA ACTIVATE' },
    { key: '120', value: 'SUMA ACTIVATE AGRO' },
    { key: '130', value: 'SUMA ACTIVATE PLUS' },
    { key: '140', value: 'SUMA REFUERZO' }
  ]

  const dropdownTipoPersona = [
    { key: 'natural', value: 'Natural' },
    { key: 'juridica', value: 'Juridica' }
  ]

  const dropdownClienteType = [
    { key: 'nuevo', value: 'Nuevo' },
    { key: 'activo', value: 'Activo' }
  ]

  const dropDownComCierre = [
    { key: '0.08', value: '8%' },
    { key: '0.1', value: '10%' },
    { key: '0.12', value: '12%' }
  ]

  const dropdownVehiculos = [
    { key: '1', value: '1' },
    { key: '2', value: '2 ' },
    { key: '3', value: '4' },
    { key: '4', value: '4' },
    { key: '5', value: '5' },
    { key: '6', value: '6' }
  ]

  const dropdownHipotecas = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
  ]

  const dropdownCheque = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
  ]

  const dropdownCod = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
  ]

  const dropdownJub = [
    { key: 'si', value: 'Si' },
    { key: 'no', value: 'No' }
  ]


  

  const dropdownInterestRateOptions = [
    { key: '5',value: '5' },
    { key: '6',value: '6' },
    { key: '7',value: '7' },
    { key: '8',value: '8' },
    { key: '9',value: '9' },
    { key: '10',value: '10' },
    { key: '11',value: '11' },
    { key: '12',value: '12' },
    { key: '13',value: '13' },
    { key: '14',value: '14' },
    { key: '15',value: '15' },
    { key: '16',value: '16' },
    { key: '17',value: '17' },
    { key: '18',value: '18' },
    { key: '19',value: '19' },
    { key: '20',value: '20' },
    { key: '21',value: '21' },
    { key: '22',value: '22' },
    { key: '23',value: '23' },
    { key: '24',value: '24' },
    { key: '25',value: '25' },
    { key: '26',value: '26' },
    { key: '27',value: '27' },
    { key: '28',value: '28' },
    { key: '29',value: '29' },
    { key: '30',value: '30' },
    { key: '31',value: '31' },
    { key: '32',value: '32' },
    { key: '33',value: '33' },
    { key: '34',value: '34' },
    { key: '35',value: '35' },
    { key: '36',value: '36' },
    { key: '37',value: '37' },
    { key: '38',value: '38' },
    { key: '39',value: '39' },
    { key: '40',value: '40' },
    { key: '41',value: '41' },
    { key: '42',value: '42' },
    { key: '43',value: '43' },
    { key: '44',value: '44' },
    { key: '45',value: '45' },
    { key: '46',value: '46' },
    { key: '47',value: '47' },
    { key: '48',value: '48' },
    { key: '49',value: '49' },
    { key: '50',value: '50' }
  ]

  let btnRef = useRef();
  let btnShareRef = useRef();



  return (
    <Formik
        initialValues={simulationParams}
       // validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
    >




    {formik => {




    return (
        
        
        
        <div>
          <Form>
            <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
                <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                  <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                    Datos del Cliente
                  </h3>
                </div>
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-3">

                  <FormikControl
                      control='select'
                      label='Tipo de Cliente'
                      name='clienteType'
                      options={dropdownClienteType}
                      
                  />

                  { 
                           
                  formik.values.clienteType === "activo" && 
                            (
       
                  <FormikControl
                      control='input'
                      label='Número de Cliente (SAFI)'
                      name='customerNumber'
                      onBlur={(e) => {
                      
                        formik.setFieldValue('customerNumber', e.target.value);
                        ClienteData(e.target.value)
                        

  
  
                    }}
                  />

                  )}

                  { 
                           
                  formik.values.clienteType === "nuevo" && 
                            (
       
                  <FormikControl
                      control='input'
                      label='Nombre del Cliente'
                      name='clientName'
                  />

                  )}  

                  <FormikControl
                    control='date'
                    label='Fecha de Nacimiento'
                    name='datebirth'
                />    



                


                  <FormikControl
                    control='select'
                    label='Tipo de Persona'
                    name='tipoPersona'
                    options={dropdownTipoPersona}  
                  />
                  </dl>
                </div>
              </div>

            <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Datos de Simulación
                </h3>
              </div>

              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4"> 

                <FormikControl
                    control='select'
                    label='Tipo Producto'
                    name='productCred'
                    options={dropDownProductOptions}
                    onChange={(e) => {
                      

                      formik.setFieldValue('productCred', e.target.value);
                      //setTotalCredito(formik.values.credAmount);
                      if(e.target.value === '140'){
                        formik.setFieldValue('comCierre', 0.1)
                      }
                   
                      getPlazos(e.target.value);
                      getAccesorios(formik, e.target.value);
                      //sumAccesorios(formik);


                  }}
                    
                />

                <FormikControl
                    control='input'
                    label='Tasa'
                    name='interestRate'
                    type='number'
                />

                {/* <FormikControl
                    control='select'
                    label='Tasa'
                    name='interestRate'
                    options={dropdownInterestRateOptions}
                /> */}

                <FormikControl
                    control='select'
                    label='Destino del Credito'
                    name='destCredID'
                    options={optionsDestinos()}
                /> 

                <FormikControl
                    control='select'
                    label='Tipo de Garantía'
                    name='garantia'
                    options={dropdownGarantias}
                />

                <FormikControl
                    control='select'
                    label='Plazo'
                    name='termNumber'
                    options={optionsPlazos()}
                />

                <FormikControl
                    control='select'
                    label='Frecuencia de Capital'
                    name='frequencyCap'
                    options={dropdownFrecuenciaOptions}
                    onChange={(e) => {

                      formik.setFieldValue('frequencyCap', e.target.value);

                      let termInMonthsFrecuencia = Number(0.00);
                      var sel = document.getElementById("termNumber");
                      var text= sel.options[sel.selectedIndex].text;   

                      termInMonthsFrecuencia = text.replace(/[^\d]/g, '');

                      if(e.target.value === 'M'){

                        formik.setFieldValue('feeNumber', termInMonthsFrecuencia);

                      }else if(e.target.value === 'B'){

                        formik.setFieldValue('feeNumber', Number(termInMonthsFrecuencia)/2);

                      }else if(e.target.value === 'T'){

                        formik.setFieldValue('feeNumber', Number(termInMonthsFrecuencia)/3);

                      }else if(e.target.value === 'S'){

                        formik.setFieldValue('feeNumber', Number(termInMonthsFrecuencia)/6);

                      }

                  }}
                    
                />

                <FormikControl
                    control='input'
                    label='Cuotas'
                    name='feeNumber'
                    disabled
                />

                <FormikControl
                    control='date'
                    label='Fecha de Inicio del Crédito'
                    name='inicialDate'
                /> 

                
               
                <FormikControl
                    control='input'
                    label='Monto Solicitado'
                    name='credAmount'
                    type='number'

                    
                />

                <FormikControl
                    control='select'
                    label='Comisión por Cierre'
                    name='comCierre'
                    options={dropDownComCierre}
                />

                <FormikControl
                    control='input'
                    label='Monto Refinanciado'
                    name='montoRefinanciado'
                    type='number'

                    
                />

                

                

                

                
                
                

                
                

                

                <FormikControl
                    control='select'
                    label='Dia Mes Pago'
                    name='monthDayCap'
                    options={dropdownDiaMesPagoOptions}
                />

                <FormikControl
                    control='select'
                    label='Cheque Certificado'
                    name='chequeCert'
                    options={dropdownCheque}
                />

                <FormikControl
                    control='select'
                    label='Tiene Codeudores'
                    name='codeudores'
                    options={dropdownCod}
                />

                

                { 
                           
                  formik.values.codeudores === "si" && 
                            (
       
                  <FormikControl
                    control='date'
                    label='Fecha de Nacimiento Codeudor'
                    name='datebirthCodeudor'
                />    

                  )} 

              <FormikControl
                    control='select'
                    label='Es Jubilado o Pensionado'
                    name='jubilado'
                    options={dropdownJub}
                />


                

                
                
                </dl>   
              </div>

                        
            </div>


              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Gastos Legales
                </h3>
              </div>
                      <div className="px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-3">  

                            
                            
                          <FormikControl
                            control='select'
                            label='Gasto Legal'
                            name='gastosLegales'
                            options={dropdownGastosLegales}
                          />


                           { 
                           
                           formik.values.gastosLegales === "contPrendarioHipotecaVehiculo" && 
                            (

                              <FormikControl
                                control='select'
                                label='Vehiculo Extra'
                                name='vehiculoExtra1'
                                options={dropdownVehiculos}
                              />
                            )
                            
                            
                          
                          } 

                          { 
                           formik.values.gastosLegales === "contPrendarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='Vehiculo Extra'
                                name='vehiculoExtra2'
                                options={dropdownVehiculos}
                              />
                            )
                          }

                          { 
                           formik.values.gastosLegales === "contInmobiliarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='Vehiculo Extra'
                                name='vehiculoExtra3'
                                options={dropdownVehiculos}
                              />

                              )
                            }

                            { 
                           formik.values.gastosLegales === "contInmobiliarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='Finca Adicional'
                                name='finca1'
                                options={dropdownVehiculos}
                              />

                              )
                            }

                            { 
                           formik.values.gastosLegales === "contInmobiliarioEscrituraPublica" && 
                            (

                              <FormikControl
                                control='select'
                                label='2da Hipoteca'
                                name='hipoteca1'
                                options={dropdownHipotecas}
                              />

                              )
                            }
                          </dl>   

                        </div>

              </div>

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Gastos Legales Especiales
                </h3>
              </div>
                      <div className="px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-3">  

                            
                            
                          <FormikControl
                            control='select'
                            label='Alteracion de Turno'
                            name='alteracionTurno'
                            options={dropdownHipotecas}
                            value='no'
                          />
                   

                              {/* <FormikControl
                                control='select'
                                label='Escritura de Hipoteca de bienes muebles, con valor exceso + de $30000'
                                name='escrituraHipotecaMueblesValorExceso'
                                options={dropdownHipotecas}
                                
                              />
                           
                            

                          

                          <FormikControl
                            control='select'
                            label='Escritura de Hipoteca de bienes inmuebles, con valor exceso + de $50000'
                            name='escrituraHipotecaInmueblesValorExceso'
                            options={dropdownHipotecas}
                            
                          /> */}

                          <FormikControl
                            control='select'
                            label='Firma a Ruego'
                            name='firmaRuego'
                            options={dropdownHipotecas}
                          />

{ 
                           
                           formik.values.firmaRuego === "si" && 
                            (

                              <FormikControl
                                control='select'
                                label='Cantidad de Firmantes'
                                name='firmantesExtra1'
                                options={dropdownVehiculos}
                              />
                            )
                            
                            
                          
                          } 



                           
                          </dl>   

                        </div>

              </div>

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Gastos de Seguros
                </h3>
              </div>
                      <div className="px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-3">  

                            
                            
                          <FormikControl
                              control='input'
                              label='Seguro de Vida'
                              name='montoSeguroDeVida'
                              type = 'number'
                              
                          />

                          <FormikControl
                              control='input'
                              label='Retencion Seguro de Vida'
                              type = 'number'
                              name='montoRetencionSeguroDeVida'
                              
                          />

                          <FormikControl
                              control='input'
                              label='Seguro de Incendio'
                              name='montoSeguroDeIncendio'
                              type = 'number'
                          />

                          <FormikControl
                              control='input'
                              label='Retención Seguro Incendio'
                              name='montoRetencionSeguroIncendio'
                              type = 'number'
                          />

                          <FormikControl
                              control='input'
                              label='Seguro de Auto'
                              name='montoSeguroDeAuto'
                              type = 'number'
                          />

                          <FormikControl
                              control='input'
                              label='Seguro ISA'
                              name='montoSeguroISA'
                              type = 'number'
                          />

                          <FormikControl
                              control='input'
                              label='Retención Seguro ISA'
                              name='montoRetencionSeguroISA'
                              type = 'number'
                          />

                          </dl>   

                        </div>

              </div>

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Otros Accesorios
                </h3>
              </div>
                      <div className="px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-3">  

                            
                            
                          <FormikControl
                              control='input'
                              label='Comisión del Vendedor'
                              name='montoComisionVendedor'
                              type = 'number'
                          />

                          <FormikControl
                              control='input'
                              label='F.W.L.A.'
                              name='montoFWLA'
                              type = 'number'
                          />

                          <FormikControl
                              control='input'
                              label='Pago Referido'
                              name='montoPagoReferido'
                              type = 'number'
                          />

                          <FormikControl
                              control='input'
                              label='Otros Gastos'
                              name='montoOtrosGastosAccesorios'
                              type = 'number'
                          />

                          

                          </dl>   

                        </div>

              </div>

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              
              <div className="px-4 py-5 sm:px-6">
                

              <button
                  ref={btnRef}
                  className="bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-orange-500 rounded"
                  type="button" onClick={() => {


                const today = new Date();
                const birthDate = new Date(formik.values.datebirth);
                const codebtorDate = new Date(formik.values.datebirthCodeudor);
                let gastoLegalMonto = 0;

                const seventyYearsAgo = new Date();
                seventyYearsAgo.setFullYear(seventyYearsAgo.getFullYear() - 71);

                if (birthDate <= seventyYearsAgo && formik.values.clienteType === "nuevo") {
                alert("El deudor o codeudor no pueden ser mayores a 70 años.");
                return; 
                }


                if(btnRef.current){
                    btnRef.current.setAttribute("disabled", "disabled");
                }

                if(formik.values.tipoPersona === "juridica"){

                    gastoLegalMonto = gastoLegalMonto + Number(100)

                }
                    
                switch (formik.values.gastosLegales) {
                    case 'contPrendario':
                        gastoLegalMonto += Number(50);
                        break;
                    case 'contEscrituraBienMuebleBPN':
                        gastoLegalMonto += Number(350);
                        break;
                    case 'contPrendarioHipotecaVehiculo':
                        gastoLegalMonto += Number(125);
                        if (formik.values.vehiculoExtra1 > 0) {
                            gastoLegalMonto += Number(75) * Number(formik.values.vehiculoExtra1);
                        }
                        break;
                    case 'contInmobiliarioEscrituraPublica':
                        gastoLegalMonto += Number(650);
                        if (formik.values.vehiculoExtra3 > 0) {
                            gastoLegalMonto += Number(150) * Number(formik.values.vehiculoExtra3);
                        }
                        if (formik.values.finca1 > 0) {
                            gastoLegalMonto += Number(150) * Number(formik.values.finca1);
                        }
                        if (formik.values.hipoteca1 === 'si') {
                            gastoLegalMonto += Number(100);
                        }
                        break;
                    case 'contPrendarioEscrituraPublica':
                        gastoLegalMonto += Number(275);
                        if (formik.values.vehiculoExtra2 == 1) {
                            gastoLegalMonto += Number(75);
                        } else if (formik.values.vehiculoExtra2 > 1) {
                            let calculation = Number(formik.values.vehiculoExtra2) - 1;
                            gastoLegalMonto += Number(75) + (calculation * Number(150));
                        }
                        break;
                    default:
                        break;
                }
                                        
                    
                if(formik.values.firmaRuego === 'si'){

                gastoLegalMonto = gastoLegalMonto + (Number(30) * formik.values.firmantesExtra1)

                }
                
                if(formik.values.alteracionTurno === 'si'){

                gastoLegalMonto = gastoLegalMonto + Number(350)

                }

                gastoLegalMontoImpuesto = gastoLegalMonto * Number(0.07)
                gastoLegalConImpuesto = gastoLegalMonto + gastoLegalMontoImpuesto

                var sel = document.getElementById("termNumber");
                var text= sel.options[sel.selectedIndex].text;   
                termInMonths = text.replace(/[^\d]/g, '');

                const ageDetailsDebtor = calculateAgeDetails(formik.values.datebirth,  Number(termInMonths));
                const ageDetailsCodebtor = calculateAgeDetails(formik.values.datebirthCodeudor,  Number(termInMonths));



    
                if(formik.values.chequeCert === 'si'){

                chequeCertificadoMonto = Number(10)

                }else{
                chequeCertificadoMonto = Number(0.00)

                }
                chequeCertificadoMontoImpuesto = chequeCertificadoMonto * Number(0.07)
                chequeCertificadoConImpuesto = chequeCertificadoMonto + chequeCertificadoMontoImpuesto



                pagoReferidoMonto = Number(formik.values.montoPagoReferido)
                pagoReferidoMontoImpuesto = Number(0.00)
                pagoReferidoConImpuesto = pagoReferidoMonto + pagoReferidoMontoImpuesto

                
    

                let memorialExequialMontoDebtor = calculateExequialExpenses(ageDetailsDebtor.ageStartingCredit,  Number(termInMonths));
                let comisionMemorialMontoDebtor = memorialExequialMontoDebtor.totalExequiasConImpuesto - memorialExequialMontoDebtor.memorialExequialConImpuesto;

                let memorialExequialMontoCodebtor = {
                    totalExequiasMonto: 0,
                    totalExequiasMontoImpuesto: 0,
                    totalExequiasConImpuesto: 0,
                    memorialExequialMonto: 0,
                    memorialExequialMontoImpuesto: 0,
                    memorialExequialConImpuesto: 0,
                    comisionMemorialMonto: 0,
                    comisionMemorialMontoImpuesto: 0,
                    comisionMemorialConImpuesto: 0,
                }

                let comisionMemorialMontoCodebtor = 0;


            if(formik.values.codeudores === 'si'){
            memorialExequialMontoCodebtor = calculateExequialExpenses(ageDetailsCodebtor.ageStartingCredit,  Number(termInMonths));

            comisionMemorialMontoCodebtor = memorialExequialMontoCodebtor.totalExequiasConImpuesto - memorialExequialMontoCodebtor.memorialExequialConImpuesto;
            }


            gastoTotalAccesoriosSinCierre = gastoLegalMonto + pagoReferidoMonto + chequeCertificadoMonto + memorialExequialMontoDebtor.memorialExequialConImpuesto + memorialExequialMontoCodebtor.memorialExequialConImpuesto + comisionMemorialMontoDebtor + comisionMemorialMontoCodebtor + Number(formik.values.montoSeguroDeVida)+ Number(formik.values.montoRetencionSeguroDeVida) + Number(formik.values.montoSeguroDeIncendio)+ Number(formik.values.montoRetencionSeguroIncendio) + Number(formik.values.montoSeguroDeAuto) + Number(formik.values.montoSeguroISA) + Number(formik.values.montoRetencionSeguroISA) +  Number(formik.values.montoComisionVendedor) + Number(formik.values.montoFWLA) + Number(formik.values.montoPagoReferido) + Number(formik.values.montoOtrosGastosAccesorios) + ( Number(formik.values.credAmount) * Number(formik.values.comCierre) )



                    //A esta variable hay que agregarle la comision de cierre.


                    const debtorFactors = calculateFactors(ageDetailsDebtor.finalAge);
                    const codebtorFactors = ageDetailsCodebtor.finalAge ? calculateFactors(ageDetailsCodebtor.finalAge) : null;
                    


// Calculate seguroDesgravamen for debtor
                    let seguroDesgravamenMontoDebtor = calculateSeguroDesgravamen(
                      formik.values.credAmount,
                      formik.values.montoRefinanciado,
                      gastoTotalAccesoriosSinCierre,
                      Number(termInMonths),
                      debtorFactors.factorSeguroDesgravamen,
                      debtorFactors.factorComisionSeguroDesgravamen,
                      debtorFactors.factorProyeccionCuotas,
                      ageDetailsDebtor.finalAge
                    );

                    // Initialize seguroDesgravamen for codebtor
                    let seguroDesgravamenMontoCodebtor = 0;

                    // Calculate only if co-debtors exist
                    if (formik.values.codeudores === 'si') {
                      seguroDesgravamenMontoCodebtor = calculateSeguroDesgravamen(
                          formik.values.credAmount,
                          formik.values.montoRefinanciado,
                          gastoTotalAccesoriosSinCierre,
                          Number(termInMonths),
                          codebtorFactors.factorSeguroDesgravamen,
                          codebtorFactors.factorComisionSeguroDesgravamen,
                          codebtorFactors.factorProyeccionCuotas,
                          ageDetailsCodebtor.finalAge
                      );
                    }






                    let timbresFiscalesMonto = calculateTimbresFiscales(formik.values.credAmount, formik.values.montoRefinanciado, ageDetailsDebtor.ageStartingCredit, formik.values.accesorios);
                    
                    gastoTotalAccesoriosSinDesgravamen = gastoLegalConImpuesto + chequeCertificadoConImpuesto + timbresFiscalesMonto + pagoReferidoConImpuesto + memorialExequialMontoDebtor.memorialExequialConImpuesto + memorialExequialMontoCodebtor.memorialExequialConImpuesto + comisionMemorialMontoDebtor + comisionMemorialMontoCodebtor + comisionPorCierreConImpuesto + Number(formik.values.montoSeguroDeVida) + Number(formik.values.montoRetencionSeguroDeVida) + Number(formik.values.montoSeguroDeIncendio) + Number(formik.values.montoRetencionSeguroIncendio) + Number(formik.values.montoSeguroDeAuto) + Number(formik.values.montoSeguroISA) + Number(formik.values.montoRetencionSeguroISA) +  Number(formik.values.montoComisionVendedor) + Number(formik.values.montoFWLA) + Number(formik.values.montoPagoReferido) + Number(formik.values.montoOtrosGastosAccesorios)



                    let gastoTotalAccesoriosDebtor = 
                        gastoLegalConImpuesto + 
                        comisionPorCierreConImpuesto + 
                        timbresFiscales +
                        seguroDesgravamenMontoDebtor.seguroDesgravamenConImpuesto +
                        seguroDesgravamenMontoDebtor.comisionDesgravamenConImpuesto +
                        seguroDesgravamenMontoDebtor.impuestoDesgravamenConImpuesto +
                        chequeCertificadoConImpuesto + 
                        pagoReferidoConImpuesto + 
                        memorialExequialMontoDebtor.memorialExequialConImpuesto +
                        comisionMemorialMontoDebtor +
                        Number(formik.values.montoSeguroDeVida) + 
                        Number(formik.values.montoRetencionSeguroDeVida) + 
                        Number(formik.values.montoSeguroDeIncendio) + 
                        Number(formik.values.montoRetencionSeguroIncendio) + 
                        Number(formik.values.montoSeguroDeAuto) + 
                        Number(formik.values.montoSeguroISA) + 
                        Number(formik.values.montoRetencionSeguroISA) + 
                        Number(formik.values.montoComisionVendedor) + 
                        Number(formik.values.montoFWLA) + 
                        Number(formik.values.montoPagoReferido) + 
                        Number(formik.values.montoOtrosGastosAccesorios)

                    let gastoTotalAccesoriosCodebtor = 0;
                    if (formik.values.codeudores === 'si') {
                      gastoTotalAccesoriosCodebtor =
                        memorialExequialMontoCodebtor.memorialExequialConImpuesto +
                        comisionMemorialMontoCodebtor +
                        seguroDesgravamenMontoCodebtor.seguroDesgravamenConImpuesto +
                        seguroDesgravamenMontoCodebtor.comisionDesgravamenConImpuesto + 
                        seguroDesgravamenMontoCodebtor.impuestoDesgravamenConImpuesto;
                    }



                    gastoTotalAccesorios = gastoTotalAccesoriosDebtor + gastoTotalAccesoriosCodebtor;



                    if(formik.values.jubilado === 'si'){

                      comisionPorCierre = (Number(formik.values.credAmount) + Number(gastoTotalAccesorios) + Number(formik.values.montoRefinanciado))  * (Number(formik.values.comCierre) / 2)

                    }else{

                      comisionPorCierre = (Number(formik.values.credAmount) + Number(gastoTotalAccesorios) + Number(formik.values.montoRefinanciado))  * (Number(formik.values.comCierre))

                    }
                    
                      comisionPorCierreImpuesto = comisionPorCierre * 0.07

                      comisionPorCierreConImpuesto = comisionPorCierreImpuesto + comisionPorCierre

                    setTotalAccesorios(gastoTotalAccesorios);



                    totalMontoFinanciadoMonto = Number(formik.values.credAmount) + Number(gastoTotalAccesorios) + Number(formik.values.montoRefinanciado)
                    
                    formik.setFieldValue('accesorios.1', Number(formik.values.montoSeguroDeVida).toFixed(2))
                    formik.setFieldValue('accesorios.1_tax', 0.00)
                    formik.setFieldValue('accesorios.1_total', Number(formik.values.montoSeguroDeVida).toFixed(2))

                    formik.setFieldValue('accesorios.3', Number(formik.values.montoComisionVendedor).toFixed(2))
                    formik.setFieldValue('accesorios.3_tax', "0.00");
                    formik.setFieldValue('accesorios.3_total', Number(formik.values.montoComisionVendedor).toFixed(2))

              
                    
                    formik.setFieldValue('accesorios.4', comisionPorCierre.toFixed(2))
                    formik.setFieldValue('accesorios.4_tax', comisionPorCierreImpuesto.toFixed(2))
                    formik.setFieldValue('accesorios.4_total', comisionPorCierreConImpuesto.toFixed(2))
                    
                    
                    formik.setFieldValue('accesorios.5', gastoLegalMonto.toFixed(2))
                    formik.setFieldValue('accesorios.5_tax', gastoLegalMontoImpuesto.toFixed(2))
                    formik.setFieldValue('accesorios.5_total', gastoLegalConImpuesto.toFixed(2))
                    

                    formik.setFieldValue('accesorios.6', 
                    (
                      seguroDesgravamenMontoDebtor.seguroDesgravamenMonto + 
                      (seguroDesgravamenMontoCodebtor?.seguroDesgravamenMonto || 0)
                    ).toFixed(2)
                  );
                  
                    formik.setFieldValue('accesorios.6_tax', 
                    (
                      seguroDesgravamenMontoDebtor.seguroDesgravamenMontoImpuesto + 
                      (seguroDesgravamenMontoCodebtor?.seguroDesgravamenMontoImpuesto || 0)
                    ).toFixed(2)
                  );
                  
                    formik.setFieldValue('accesorios.6_total', 
                    (
                      seguroDesgravamenMontoDebtor.seguroDesgravamenConImpuesto + 
                      (seguroDesgravamenMontoCodebtor?.seguroDesgravamenConImpuesto || 0)
                    ).toFixed(2)
                  );
              
                    

                    formik.setFieldValue('accesorios.7', 
                    (
                        seguroDesgravamenMontoDebtor.comisionDesgravamenMonto + 
                        (seguroDesgravamenMontoCodebtor?.comisionDesgravamenMonto || 0)
                    ).toFixed(2)
                    );

                    formik.setFieldValue('accesorios.7_tax', 
                    (
                    seguroDesgravamenMontoDebtor.comisionDesgravamenMontoImpuesto + 
                    (seguroDesgravamenMontoCodebtor?.comisionDesgravamenMontoImpuesto || 0)
                    ).toFixed(2)
                    );

                    formik.setFieldValue('accesorios.7_total', 
                    (
                    seguroDesgravamenMontoDebtor.comisionDesgravamenConImpuesto + 
                    (seguroDesgravamenMontoCodebtor?.comisionDesgravamenConImpuesto || 0)
                    ).toFixed(2)
                    );

                    

                    formik.setFieldValue('accesorios.8', 
                    (
                      seguroDesgravamenMontoDebtor.impuestoDesgravamenMonto + 
                      (seguroDesgravamenMontoCodebtor ? seguroDesgravamenMontoCodebtor.impuestoDesgravamenMonto : 0)
                    ).toFixed(2)
                  );
                    formik.setFieldValue('accesorios.8_tax', "0.00");
                    formik.setFieldValue('accesorios.8_total', 
                    (
                      seguroDesgravamenMontoDebtor.impuestoDesgravamenConImpuesto + 
                      (seguroDesgravamenMontoCodebtor?.impuestoDesgravamenConImpuesto || 0)
                    ).toFixed(2)
                  );
                    
                    
                    formik.setFieldValue('accesorios.9', Number(formik.values.montoFWLA).toFixed(2))
                    formik.setFieldValue('accesorios.9_tax', 0.00)
                    formik.setFieldValue('accesorios.9_total', Number(formik.values.montoFWLA).toFixed(2))


                    formik.setFieldValue('accesorios.10', 
                    (
                      memorialExequialMontoDebtor.memorialExequialMonto + 
                      (memorialExequialMontoCodebtor?.memorialExequialMonto || 0)
                    ).toFixed(2)
                  );
                  
                  formik.setFieldValue('accesorios.10_tax', 
                  (
                    memorialExequialMontoDebtor.memorialExequialMontoImpuesto + 
                    (memorialExequialMontoCodebtor?.memorialExequialMontoImpuesto || 0)
                  ).toFixed(2)
                );
                
                formik.setFieldValue('accesorios.10_total', 
                (
                  memorialExequialMontoDebtor.memorialExequialConImpuesto + 
                  (memorialExequialMontoCodebtor?.memorialExequialConImpuesto || 0)
                ).toFixed(2)
              );
              
                    
                    
                    formik.setFieldValue('accesorios.11', chequeCertificadoMonto.toFixed(2))
                    formik.setFieldValue('accesorios.11_tax', chequeCertificadoMontoImpuesto.toFixed(2))
                    formik.setFieldValue('accesorios.11_total', chequeCertificadoConImpuesto.toFixed(2))
                    
                    formik.setFieldValue('accesorios.12', timbresFiscalesMonto.toFixed(2))
                    formik.setFieldValue('accesorios.12_tax', 0.00)
                    formik.setFieldValue('accesorios.12_total', timbresFiscalesMonto.toFixed(2))
                    
                    formik.setFieldValue('accesorios.13', pagoReferidoMonto.toFixed(2))
                    formik.setFieldValue('accesorios.13_tax', pagoReferidoMontoImpuesto.toFixed(2))
                    formik.setFieldValue('accesorios.13_total', pagoReferidoConImpuesto.toFixed(2))
                    
                    
                    formik.setFieldValue('accesorios.15', Number(formik.values.montoSeguroDeIncendio).toFixed(2))
                    formik.setFieldValue('accesorios.15_tax', 0.00)
                    formik.setFieldValue('accesorios.15_total', Number(formik.values.montoSeguroDeIncendio).toFixed(2))
                    
                    formik.setFieldValue('accesorios.16', Number(formik.values.montoOtrosGastosAccesorios).toFixed(2))
                    formik.setFieldValue('accesorios.16_tax', 0.00)
                    formik.setFieldValue('accesorios.16_total', Number(formik.values.montoOtrosGastosAccesorios).toFixed(2))
                    
                    formik.setFieldValue('accesorios.17', Number(formik.values.montoSeguroDeAuto).toFixed(2))
                    formik.setFieldValue('accesorios.17_tax', 0.00)
                    formik.setFieldValue('accesorios.17_total', Number(formik.values.montoSeguroDeAuto).toFixed(2))
                    
                    formik.setFieldValue('accesorios.19', Number(formik.values.montoRetencionSeguroDeVida).toFixed(2))
                    formik.setFieldValue('accesorios.19_tax',0.00)
                    formik.setFieldValue('accesorios.19_total', Number(formik.values.montoRetencionSeguroDeVida).toFixed(2))
                    
                    formik.setFieldValue('accesorios.18', Number(formik.values.montoRetencionSeguroIncendio).toFixed(2))
                    formik.setFieldValue('accesorios.18_tax', 0.00)
                    formik.setFieldValue('accesorios.18_total', Number(formik.values.montoRetencionSeguroIncendio).toFixed(2))

                    formik.setFieldValue('accesorios.20', 
                    (
                      memorialExequialMontoDebtor.comisionMemorialMonto + 
                      (memorialExequialMontoCodebtor?.comisionMemorialMonto || 0)
                    ).toFixed(2)
                  );
                  
                  formik.setFieldValue('accesorios.20_tax', 
                  (
                    memorialExequialMontoDebtor.comisionMemorialMontoImpuesto + 
                    (memorialExequialMontoCodebtor?.comisionMemorialMontoImpuesto || 0)
                  ).toFixed(2)
                );
                
                formik.setFieldValue('accesorios.20_total', 
                (
                  memorialExequialMontoDebtor.comisionMemorialConImpuesto + 
                  (memorialExequialMontoCodebtor?.comisionMemorialConImpuesto || 0)
                ).toFixed(2)
              );
              
                    
                    
                    formik.setFieldValue('accesorios.21', Number(formik.values.montoSeguroISA).toFixed(2))
                    formik.setFieldValue('accesorios.21_tax', 0.00)
                    formik.setFieldValue('accesorios.21_total', Number(formik.values.montoSeguroISA).toFixed(2))
                    
                    
                    formik.setFieldValue('accesorios.22', Number(formik.values.montoRetencionSeguroISA).toFixed(2))
                    formik.setFieldValue('accesorios.22_tax', 0.00)
                    formik.setFieldValue('accesorios.22_total', Number(formik.values.montoRetencionSeguroISA).toFixed(2))

                    formik.setFieldValue('totalMontoFinanciado', totalMontoFinanciadoMonto.toFixed(2))

                    formik.setFieldValue('comAper', gastoTotalAccesorios.toFixed(2))
                    





                    
                  } }
                >
                  Calcular Accesorios
                </button>
                </div>
              </div>
              
              
              {/* New Tabla */}
              <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Resumen De Accesorios
                      </h3>
                  </div>
                  <div className="px-4 py-5 sm:px-6">
                  <div className="flex flex-col">
                      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                          <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                          <table className="min-w-full">
                              <thead>
                              <tr>
                                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Accesorio
                                  </th>
                                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Monto Accesorio
                                  </th>
                                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Impuesto (ITBMS)
                                  </th>
                                  
                                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  TOTAL
                                  </th>
                              </tr>
                              </thead>
                              <tbody>
                              
                              { formik.values.accesoriosList.map( acc => {
                                
                                return(
                                  
                                  <tr className="bg-white">
                                  
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                      {acc["Etiqueta"]}
                                      
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      <FormikControl
                                          control='input'
                                          label={` `}
                                          name={`accesorios.${acc["AccesorioID"]}`}
                                      />
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      <FormikControl
                                          control='input'
                                          label={` `}
                                          name={`accesorios.${acc["AccesorioID"]}`+`_tax`}
                                      />
                                    
                                      </td>
                                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                      <FormikControl
                                          control='input'
                                          label={` `}
                                          name={`accesorios.${acc["AccesorioID"]}`+`_total`}
                                      /> 
                                      </td>
                                  </tr>   
                                )
                              })}
                              

                              </tbody>
                          </table>
                          </div>
                      </div>
                      </div>
              
                  
                  </div>
              </div>
              {/* New Tabla */}

              

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Totales {totalAccesoriosState ? totalAccesoriosState : 0.00}
                </h3>
              </div>
                      <div className="px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 col-gap-3 gap-x-8 sm:grid-cols-12">  
                          
                            <div className="col-span-12"> 

                          <FormikControl
                              control='input'
                              label='Total Accesorios'
                              name='comAper'
                              disabled
                          />
                          <FormikControl
                              control='input'
                              label='Total Monto Financiado'
                              name='totalMontoFinanciado'
                              disabled
                          />
                          </div>

                          
                          </dl>   

                        </div> 

              </div>

              

              


              

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              
                      <div className="px-4 py-5 sm:px-6">
                      <ButtonsSimulacion />  

                        </div>

              </div>

              

              

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
                <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                  Amortizaciones Simuladas
                </h3>
              </div>
                <TablaSimulacion amortization={simulation} />

              </div>

              <div className="mx-6 mt-6 bg-white shadow  sm:rounded-lg">
              
                      <div className="px-4 py-5 sm:px-6">
                      <ButtonsShare />  

                        </div>

              </div>

               




          </Form>
        </div>
    )
    }}
    </Formik>
  );
}

export default DatosSimulacion;
