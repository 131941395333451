import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import {Bars} from 'react-loader-spinner'
// import Template from '../Template'

import Steps from './Steps'
import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue';


const TipoSexo = {"M": "Masculino", "F": "Femenino"}
const TipoContacto = {"Teléfono": "Teléfono", "Correo": "Correo", "WhatsApp": "WhatsApp", "Visita": "Visita" }
const TipoCliente = {"Independiente": "Independiente", "Jurídico": "Jurídico", "Empleado Planilla": "Empleado Planilla", "Jurídico Planilla": "Jurídico Planilla" }
const TipoSociedad = {"Asociación Civil": "Asociación Civil", "Sociedad Civil": "Sociedad Civil", "Sociedad Anónima": "Sociedad Anónima", "Comandita Simple": "Comandita Simple"}
const SiNo = {"Sí": "Sí", "No": "No" }

const DetalleProspecto = (props) => {
    return (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">  
            <Steps prospect={props.prospect} />
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="flex justify-between items-center">
                    <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    Detalle de Prospecto: <span className="text-orange-600">{props.prospect.zProspectoID}</span>
                    </h3>  
                    
                    {
                        false &&
                        <span className="text-orange-600">
                            <a href={`/prospectos/${props.prospect.id}/evaluation`} className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                </svg>
                                Evaluación
                            </a>
                        </span>
                    }
                </div>
                { false &&
                    <div className="m-4 mt-0 inline-flex float-right">
                        <span className="ml-2 rounded-md shadow-sm ">
                            <a target="_blank" href={`/prospectos/${props.prospect.id}/evaluation`}>
                            <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                            </svg> Evaluación
                            </button>                    
                            </a>
                        </span>
                    </div>
                }                  
                  
            </div>
            <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                <FieldValue label={"Nombre Completo"}>{props.prospect.NombreCompleto }</FieldValue>
                </dl>
                <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                <FieldValue label={"Sucursal"}>{props.prospect.Sucursal }</FieldValue>
                <FieldValue label={"Responsable"}>{props.prospect.responsible }</FieldValue>
                <FieldValue label={"Tipo Motivo"}>{props.prospect.TipoMotivo}</FieldValue>
                <FieldValue label={"Motivo"}>{props.prospect.Motivo}</FieldValue>
                </dl>
            </div>
            { props.prospect.TipoMotivo.length == 0 &&
                <div className="mb-4 px-4 py-5 sm:px-6">
                    <span className="m-4 self-center text-red-500">
                    Debe tener Tipo Motivo seleccionado para poder avanzar
                    </span>
                </div>   
            }
        </div>    
    )

}



export default DetalleProspecto;
